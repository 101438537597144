import { useContext, useEffect } from "react";
import styled from "styled-components";
import DarkContext from "../gerais/DarkContext";
import { useNavigate } from "react-router-dom";
import Escudo from "../pag_2/Escudo";
import { ligas } from "../ligas/contexts";
import { useState } from "react";
import { getTorneios } from "../api";

export default function Torneios(){
    const {user}=useContext(DarkContext)
    const navigate=useNavigate()
    const [torneios,setTorneios]=useState(false)
    function buscar(){
        if(!user)return navigate('/login')
        setTorneios()
        const promise=getTorneios(user.id)
        promise.then(res=>{
            setTorneios(res.data)
        })
    }
   useEffect(buscar,[])
    return(
        <Tudo>
            {torneios?torneios.map((torn,index)=>(
                <Torneio onClick={()=>navigate(`/torneio`)}>
                    <h1>{torn.nome}</h1>
                    <main>
                    <Part>
                        <Topo>
                            <ion-icon name="people"></ion-icon>
                            <p><strong>{torn.jogadores.length}</strong></p>
                        </Topo>
                        <p>jogadores</p>
                    </Part>
                    <Part>
                        <Topo>
                            <ion-icon name="cash"></ion-icon>
                            <p><strong>0 $</strong></p>
                        </Topo>
                        <p>inscrição</p>
                    </Part>
                    <Part>
                        <Topo>
                            <ion-icon name="ribbon"></ion-icon>
                            <p><strong>50 $</strong></p>
                        </Topo>
                        <p>prêmio</p>
                    </Part>
                    </main>
                </Torneio>
            )):<></>}
            <Criar >
                <p>Criar torneio (em breve)</p>
            </Criar>
        </Tudo>
    )
}
const Topo=styled.div`
`
const Part=styled.div`
flex-direction:column;
width:32%;background-color:#efac75;
border-radius:12px;
ion-icon{
font-size:25px;margin:0 5px 0 5px;
}

p{
font-size:13px;
strong{font-size:20px;font-weight:500;}
}
`
const Torneio=styled.div`cursor:pointer;
flex-direction:column;
width:90%;height:100px;background-color:#efac75;
margin:20px 0 0 0;border-radius:12px;
h1{margin:10px 0 20px 0;
font-size:20px;font-weight:500;
}
main{width:100%;
display:flex;align-items:center;justify-content:space-evenly;
}
`
const Criar=styled.div`
flex-direction:column;justify-content:center;
width:90%;height:50px;background-color:#E8E8E8;
margin:20px 0 0 0;border-radius:12px;
font-size:18px;
`
const Tudo=styled.div`
background-color:white;
width:100%;
height:calc(100% - 0px);
max-width:370px;
box-sizing:border-box;
height:100%;width:98%;
overflow:hidden;
overflow-y:scroll;
::-webkit-scrollbar {
    width: 15px;
  }
  ::-webkit-scrollbar-track {
    border-radius:7.5px;
  }
  ::-webkit-scrollbar-thumb {
    background:#EFEFEF; 
    border-radius:7.5px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background:#cecece;
  }
    flex-direction:column;

`