import styled from "styled-components"
import { ligas } from "../ligas/contexts"
import { useParams } from "react-router-dom"
import { getApostasCriarPost, getPartidas, ordenarApostas } from "../api"
import { useEffect, useState } from "react"
import { JoguinhoFut } from "../pag_3/JoguinhoFut"
import Loader from "../pag_2/Loader"
import Apostinha from "../pag_1/Apostinha"

export default function CriarEscAposta(){
    const [apostas,setApostas]=useState(false)
    const {liga,manvis}=useParams()
    function buscarApostas(){
        const promise=getApostasCriarPost(liga,manvis)
        promise.then(res=>{
            const ordenada=ordenarApostas(res.data,4)
            setApostas(ordenada)
        })
      }
      useEffect(buscarApostas,[])
    return (
        <Tudo>
            <Lista>
                {apostas?apostas.map((apo,index)=>(
                    <Apostinha apo={apo} doCriar={1}/>
                )):<Loader/>}
            </Lista>
        </Tudo>
    )
}
const Tudo=styled.div`justify-content:center;
position:fixed;width:100vw;height:100vh;background-color:grey;
`
const Lista=styled.div`
height:80%;width:400px;flex-direction:column;
overflow:hidden;
overflow-y:scroll;
  ::-webkit-scrollbar {width:12px}
  ::-webkit-scrollbar-thumb {background: #00702c; border-radius:5px;}
  ::-webkit-scrollbar-thumb:hover {background: #006028;}
`
const Camp=styled.div`border-radius:10px;
img{height:80%;margin-right:5px}
width:100%;height:50px;background-color:white;margin-bottom:10px;
`