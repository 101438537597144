import { useContext } from 'react'
import styled from 'styled-components'
import DarkContext from '../DarkContext'
export default function SeletorTabela2(){
    const {
        handicap,setHandicap,
      }=useContext(DarkContext)
    return(
        <Tudo>
           <main>
                <Escolha selected={handicap === -1} onClick={()=>{setHandicap(-1)}}>
                    <EmCasa  color='aa6d11'><h1>Perdendo</h1></EmCasa>
                </Escolha>
                <Escolha selected={handicap === 0} onClick={()=>{setHandicap(0)}}>
                    <EmCasa  color='aaa511'><h1>Empatando</h1></EmCasa>
                </Escolha>
                <Escolha selected={handicap === 1} onClick={()=>{setHandicap(1)}}>
                    <EmCasa  color='86aa11'><h1>Vencendo</h1></EmCasa>
                </Escolha>
                
                </main>
        </Tudo>
    )
}
const EmCasa=styled.div`
font-size:11px;
justify-content:center;
color:white;
border-radius:3px;
width:65px;
height:28px;
background-color:#${props=>props.color};
`
const Escolha=styled.button`
background-color:${props=>props.selected?'#e5e5e5':'transparent'};
font-size:12px;
justify-content:center;
color:#545454;
width:80px;
height:50px;
border-radius:5px;

`
const Tudo=styled.div`
width:100%;
max-width:700px;
height:65px;
justify-content:space-evenly;
    div{
        flex-direction:column;
    }
main{display:flex;justify-content:center;}

`
