//baseURL: 'https://football-back.herokuapp.com'
import axios from "axios"
import styled from "styled-components";

const api = axios.create({
  //baseURL:'http://localhost:4000'
  baseURL: 'https://foottball-back.onrender.com'
})
export const postCadastro = async (form) => {
  return api.post(`/cadastro`,form)
}
export const postLogin = async (form) => {
  return api.post(`/login`,form)
}
export const getTorneios = async (id) => {
  return api.get(`/torneios/${id}`)
}
export const postTorneios = async (idAdm,listaConv) => {
  return api.post(`/torneios`,{idAdm,listaConv})
}
export const getPodeConvidar = async (nome) => {
  return api.get(`/podeconvidar/${nome}`)
}



export const getPartidas = async (agrupagem) => {
  let strCamps='';for(let k=0;k<agrupagem.length;k++){strCamps+=`${agrupagem[k]}${k==agrupagem.length-1?'':'-'}`}
  return api.get(`/partidasgerais?camps=${strCamps}`)
}
export const getApostas = async (pageBet) => {
  return api.get(`/apostasgerais/${pageBet}`)
}
export const getGuru = async (camp,mandante,visitante)=>{
  return api.get(`/guru/${camp}/${mandante}/${visitante}`)
}
export const getPreFlop = async (pf)=>{
  const camp=pf[0]+pf[1]+pf[2]+pf[3]
  const mandante=pf[4]+pf[5]+pf[6]
  const visitante=pf[7]+pf[8]+pf[9]
  return api.get(`/preflop/${camp}/${mandante}/${visitante}`)
}
export const getTabela = async (camp,page,estadia,metade,handicap)=>{
  return api.get(`/tabelas/${camp}/${page}?estadia=${estadia}&metade=${metade}&handicap=${handicap}`)
}
export const getTime = async (camp,time)=>{
  return api.get(`/times/${camp}/${time}`)
}
export const getPartida = async (camp,manvis)=>{
  return api.get(`/partida/${camp}/${manvis}`)
}
export const getClassif = async (camp)=>{
  return api.get(`/classificacao/${camp}`)
}
export const getAnalise = async (camp,mandante,visitante,query)=>{
  const {grandeza,metade,c,asc,valor}=query
  return api.get(`analise/${camp}/${mandante}/${visitante}?grandeza=${grandeza}&metade=${metade}&c=${c}&asc=${asc}${grandeza==2||grandeza==6?'&valor='+valor:''}`)
}
export const getListaAnalise = async (camp,time,query,manvis)=>{
  const {grandeza,metade,c,asc,valor,estadia}=query
  return api.get(`lista-analise/${camp}/${time}/${manvis}?grandeza=${grandeza}&metade=${metade}&c=${c}&asc=${asc}&valor=${valor}&estadia=${estadia}`)
}
export const getResultados = async (resultados,contagem)=>{
  const {tipos,ev,camps}=resultados
  let strTipos='';for(let k=0;k<tipos.length;k++){strTipos+=`${tipos[k]}${k==tipos.length-1?'':'-'}`}
  let strCamps='';for(let k=0;k<camps.length;k++){strCamps+=`${camps[k]}${k==camps.length-1?'':'-'}`}
  return api.get(`resultados?camps=${strCamps}&tipos=${strTipos}&ev=${ev}&contagem=${contagem}`)
}
export const getResultadosSemanas = async (resultados)=>{
  const {tipos,ev,camps}=resultados
  let strTipos='';for(let k=0;k<tipos.length;k++){strTipos+=`${tipos[k]}${k==tipos.length-1?'':'-'}`}
  let strCamps='';for(let k=0;k<camps.length;k++){strCamps+=`${camps[k]}${k==camps.length-1?'':'-'}`}
  return api.get(`resultadosSemanas?camps=${strCamps}&tipos=${strTipos}&ev=${ev}`)
}
export function ordenarApostas(list,ordBet){
  if(ordBet==2)return list
  const nova=[]
  const used=[]
  const valorInicial=ordBet>0?-Infinity:Infinity
  for(let i=0;i<list.length;i++){
    let objeto={odd:valorInicial,chance:valorInicial,ev:valorInicial}
    let indice=null
    for(let k=0;k<list.length;k++){
        if(used.includes(k))continue
        if(ordBet==1?list[k].odd>objeto.odd:ordBet==-1?list[k].odd<objeto.odd:
          ordBet==4?list[k].ev>objeto.ev:ordBet==-4?list[k].ev<objeto.ev:
          ordBet==3?list[k].chance>objeto.chance:list[k].chance<objeto.chance){
            objeto={...list[k]}
            indice=k
        }
    }
    nova.push(objeto)
    used.push(indice)
  }
  return nova
}
export const getApostasCriarPost = async (camp,manvis) => {
  return api.get(`/apostascriar/${camp}/${manvis}`)
}
export const getInfosCriarPost = async (camp,manvis,infovalor) => {
  return api.get(`/infoscriar/${camp}/${manvis}/${infovalor}`)
}
export const getJogosResultado = async (camp) => {
  return api.get(`/postresultadocamp/${camp}`)
}