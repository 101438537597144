import { useContext, useEffect, useState } from "react"
import styled from "styled-components"
import React from 'react';
import DarkContext from "../gerais/DarkContext";
import Loader from "../pag_2/Loader";
import { ordenarApostas } from "../api";
import Apostinha from "./Apostinha";
import { useParams } from "react-router-dom";
export default function ListaApostas({apostas,paginaInteira}){
    const {contexts,resultados,keys,setKeys,ordBet,setOrdBet
        }=useContext(DarkContext)
    function setarOrdBet(num){
      setKeys({...keys,result:0})
      if(ordBet==num&&num!=2){
        setOrdBet(-num)
      }else{
        setOrdBet(num)
      }
    }
    return(
         <Tudo paginaInteira={paginaInteira}>
            <aside>
                <section>
                    <P onClick={()=>setarOrdBet(4)} >
                      {ordBet==4||ordBet==-4?<ion-icon name={`caret-${ordBet>0?'down':'up'}-outline`}/>:<></>}
                      ev
                    </P>
                </section>
                <P onClick={()=>setarOrdBet(2)}>
                  {ordBet==2?<ion-icon name={`caret-${ordBet>0?'down':'up'}-outline`}/>:<></>}
                  <ion-icon name="time-outline"/>
                </P>
                <article>
                  <P onClick={()=>setarOrdBet(1)}>
                    {ordBet==1||ordBet==-1?<ion-icon name={`caret-${ordBet>0?'down':'up'}-outline`}/>:<></>}
                    odd
                  </P>
                </article>
            </aside>
            <Quadro>
                {apostas?apostas.map((apo,index)=>
                    <Apostinha setarKeys={()=>setKeys({...keys,result:index})} id={`result${index}`} apo={apo}/>
                ):<Loader/>}
                <MargemBaixo/>
            </Quadro>
        </Tudo>
    )
}
const MargemBaixo=styled.div`
min-height:40px;width:50px;background-color:;
`
const Potencial=styled.div`height:40px;margin-top:10px;
h2{color:green;font-weight:700;font-size:30px;
  small{font-size:18px;font-weight:500;margin-right:3px;}}
`
const Quadro=styled.div`
height:calc(100% - 40px);
width:100%;
display:flex;
flex-direction:column;
align-items:center;
overflow:hidden;
overflow-y:scroll;
::-webkit-scrollbar {
    width:12px
  }
  ::-webkit-scrollbar-track {
    border-top-right-radius:5px;
    border-bottom-right-radius:5px;
  }
  ::-webkit-scrollbar-thumb {
    background: #e5e5e5; 
    border-radius:5px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #cecece; 
  }`
  const P=styled.div`
padding:3px 10px 3px 10px;
min-width:25px;
margin:0 2px 0 2px;
height:20px;position:relative;
  display:flex;align-items:flex-end;justify-content:center;
  cursor:pointer;
  font-size:14px;
  color:${props=>props.ev?'white':'black'};
  background-color:#${props=>props.ev?'001c47':'CCCCCC'};
  border-radius:${props=>props.ev?'':'10px'};
  border-top-right-radius:${props=>props.ev?'20px':''};
  border-bottom-left-radius:${props=>props.ev?'20px':''};

`
const Tudo=styled.div`
box-sizing:border-box;
z-index:4;
background-color:;

width:100%;position:relative;
z-index:6;height:${props=>props.paginaInteira?'100%':'calc(100vh - 220px)'};
max-width:700px;
top:0;
flex-direction:column;
article{
    justify-content:space-between;
    width:94%;height:50px;display:flex;
    align-items:center;
}
aside{max-width:400px;
    display:flex;width:88%;height:40px;
    justify-content:space-between;
    align-items:center;;
    background-color:;
    color:white;
    box-sizing:border-box;
    padding:5px 0px 5px 0px;
    section{display:flex;width:130px;;align-items:center}
  article{display:flex;width:130px;;align-items:center;justify-content:flex-end}
  }
`
