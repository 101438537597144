import { useContext, useEffect, useState } from "react"
import DarkContext from "../gerais/DarkContext"
import styled from "styled-components"
import { useNavigate } from "react-router-dom"
import { _FAV, ligas } from "../ligas/contexts"

export default function Apostinha({reduzida,id,setarKeys,acaoComb,onBloco,apo,combinando,praSumir,acaoSumir,doCriar}){
    const {setAnalise,setNumeroGols,setNumeroEsc,setEscoAnalise,setContEstrela,contEstrela,
        postResultado,setPostResultado,seta_analise,seta_partidas,setBandeira
    }=useContext(DarkContext)
    const apos=apo||{}
    const {odd,chance,texto,camp,mandante,visitante,green,info,valor,ev,contagem}=apos
    const nome=apos.nome||reduzida.nome
    const emAberto=green==undefined
    const paths=ligas.paths
    const context=ligas.contexts[paths.indexOf(camp)]||{}
    const {listaEscudos,listaTimes}=context
    const navigate=useNavigate()
    const [estrela,setEstrela]=useState(false)
    
    function determinarEstrela(){

        const listaEstrelas=JSON.parse(localStorage.getItem(_FAV))||[]
        if(listaEstrelas.includes(nome)){
            setEstrela(true)
        }else{setEstrela(false)}
    }
    function adicionarEstrela(){
        const listaEstrelas=JSON.parse(localStorage.getItem(_FAV))||[]
        if(estrela){
            const novaLista=[]
            for(let item of listaEstrelas){
                if(item!=nome){
                    novaLista.push(item)
                }
            }
            localStorage.setItem(_FAV, JSON.stringify(novaLista))
            if(praSumir){
                acaoSumir()
                setContEstrela(contEstrela+1)
            }
        }else{
            const novaLista=[...listaEstrelas,nome]
            localStorage.setItem(_FAV, JSON.stringify(novaLista))
        }
        determinarEstrela()
    }
    useEffect(determinarEstrela,[contEstrela])
    return(
        reduzida?
    
    <Aposta 
        menor={reduzida.hc} 
        folga={reduzida.folga} 
        fixa={reduzida.noAlter} 
        onClick={()=>{reduzida.hc?reduzida.hc():adicionarEstrela()}} 
        color={'white'} backcolor={'aberta'}>
            <Estrela 
                color={emAberto?'6193dd':green==100? '6bdb89':'e5a0a0'}
                font={estrela?'yellow':emAberto?'#c1d6f2':green==100? '#b7f7c8':'#f7d4d4'}
                menor={reduzida.hc} ativo={estrela} onClick={adicionarEstrela}>
                <ion-icon name={estrela?"star":"star-outline"}></ion-icon>
            </Estrela>
            <h5>{reduzida.tex}</h5>
            <h6>{reduzida.ode}</h6>
        {!reduzida.comecou?<></>:
        reduzida.noAlter?<PreJogoCima>
            odd pré-jogo
        </PreJogoCima>:<PreJogo>
            odd pré-jogo
        </PreJogo>}
    </Aposta>
    :
    <Tudo 
        id={id} combin={onBloco} 
        mainGrande={texto.length>45}
        color={emAberto?'white':'black'} 
        backcolor={emAberto?(green===null?'cinza':'aberta'):(green==100?'vitoria':'derrota')} >
            {onBloco||combinando?<></>:
            <Estrela 
                color={emAberto?'6193dd':green==100? '6bdb89':'e5a0a0'}
                font={estrela?'yellow':emAberto?'#c1d6f2':green==100? '#b7f7c8':'#f7d4d4'}
                onClick={adicionarEstrela}>
                    <ion-icon name={estrela?"star":"star-outline"}></ion-icon>
            </Estrela>}
            <Clicavel onClick={()=>{
                if(doCriar){
                    if(doCriar==1){
                        navigate(`/criarposts/${camp}/${mandante+visitante}/${info}-${valor}`)
                    }else{
                        setPostResultado([...postResultado,apo])
                    }
                    
                }else{
                    if(combinando)return acaoComb()
                    setarKeys()
                    const grandeza=parseInt(info[0])
                    const c=parseInt(info[1])
                    const asc=parseInt(info[2])
                    const metade=parseInt(info[3])
                    setAnalise({grandeza,c,asc,metade})
                    if(grandeza==2)setNumeroGols(valor)
                    if(grandeza==6)setNumeroEsc(valor)
                    setEscoAnalise(false)
                    if(emAberto){
                        setBandeira(camp)
                        seta_analise(`${camp}-${mandante}-${visitante}`)
                        navigate(`/analise`)
                    }else{
                        seta_partidas(`${camp}-${mandante}-${visitante}`)
                        navigate(`/partidas`)
                    }
                }
        }}/> 
            <div>
                <h2><small></small></h2>
                <h4>{ev.toFixed(0)}</h4>
            </div>
            <main>
                <h1>{texto}</h1>
                <span>
                    <img src={listaEscudos[listaTimes.indexOf(mandante)]} />
                    <ion-icon name="close-outline"></ion-icon>
                    <img src={listaEscudos[listaTimes.indexOf(visitante)]} />
                </span>
            </main>
            <h3>{odd.toFixed(2)}</h3>
        </Tudo>
    )
}//h6{font-weight:700;font-size:20px}
const PreJogoCima=styled.aside`
border-top-right-radius:15px;
border-top-left-radius:15px;
top:-20px;
`
const PreJogo=styled.aside`
border-bottom-right-radius:15px;
border-bottom-left-radius:15px;
bottom:-20px;padding-bottom:2px;
`
const Estrela=styled.button`
color:${props=>props.font};
font-size:25px;
width:${props=>props.menor?34:40}px;
height:${props=>props.menor?34:40}px;
background-color:#${props=>props.color};
border-radius:50%;
position:absolute;
top:0;z-index:5;
left:-0px;
justify-content:center;
`
const Aposta=styled.div`
cursor:pointer;
position:relative;
${props=>props.fixa?`position:absolute;bottom:8px;`:''};
box-sizing:border-box;
padding:0 10px 0 47px;
justify-content:space-between;
background-color:var(--${props=>props.backcolor});
height:${props=>props.menor?34:40}px;width:96%;
border-radius:${props=>props.menor?17:20}px;
margin-top:${props=>props.folga?'10px':'5px'};
color:${props=>props.color};
h5{font-size:13px;width:calc(100% - 48px);display:flex;justify-content:flex-start;}
h6{font-weight:600;font-size:${props=>props.menor?17:20}px}
aside{
background-color:#023a8e;
font-size:12px;position:absolute;
color:white;right:15px;font-weight:400;
display:flex;justify-content:center;align-items:center;
height:20px;width:90px;
}
`
const Clicavel=styled.div`
width:calc(100% - 40px);height:100%;background-color:;
border-top-right-radius:18px;
border-bottom-right-radius:18px;
position:absolute;
top:0;
right:-0px;
justify-content:center;
`
const Tudo=styled.div`
div{
flex-direction:column;height:100%;justify-content:space-evenly;
h2{
    display:flex;align-items:center;
    font-size:20px;width:45px;
    small{font-size:11px}
    margin-bottom:16px;
}
    h4{background-color:#001c47;color:white;
        position:absolute;bottom:0;left:0;
        height:18px;
        justify-content:center;
        display:flex;align-items:center;
        font-size:14px;width:45px;
    border-bottom-left-radius:16px;
    border-top-right-radius:16px;
    
    }
}
cursor:pointer;
span{background-color:;
  display:flex;height:50%;width:90px;
  align-items:center;
  justify-content:space-evenly;
  img{height:80%;} 
  font-size:14px;
  
}
h1{font-size:13px;height:;display:flex;align-items:center;justify-content:center;}
p{font-size:17px;width:40px;}

h3{font-size:20px;font-weight:600;width:40px;margin-top:30px}
main{
    
    width:${props=>props.mainGrande?200:160}px;height:100%;background-color:;
display:flex;flex-direction:column;align-items:center;
justify-content:space-evenly;

}
position:relative;
height:70px;
background-color:var(--${props=>props.backcolor});
color:${props=>props.color};
margin:${props=>props.combin?'0 0 0px 0':'0px 0 10px 12px'};
max-width:400px;
border-radius:20px;
display:flex;
align-items:center;
justify-content:space-between;
box-sizing:border-box;
padding:0 10px 0 10px;
width:94%;

  
`
