import './gerais/App.css';
import { useEffect, useState } from 'react';
import DarkContext from './gerais/DarkContext';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import EscolherTabela from './pag_3/EscolherTabela';
import styled from 'styled-components';
import ListaPartidas from './pag_2/ListaPartidas';
import Classif from './pag_2/Classif';
import BuscarTabela from './pag_3/BuscarTabela';
import EscolherTime from './pag_3/EscolherTime';
import Time from './pag_3/Time';
import Jogo from './pag_2/Jogo';
import JogoFuturo from './pag_2/JogoFuturo';
import ListaAnalise from './pag_1/ListaAnalise';
import Menu from './pag_2/Menu';
import Resultados from './pag_1/Resultados';
import { ligas } from './ligas/contexts';
import Favoritas from './pag_1/Favoritas';
import Procurar from './pag_2/Procurar';
//import MenuInicial from './pag_2/MenuInicial';
import CriarEscLiga from './gerais/CriarEscLiga';
import CriarEscJogo from './gerais/CriarEscJogo';
import CriarEscAposta from './gerais/CriarEscAposta';
import CriarPrint from './gerais/CriarPrint';
import ResultEscLiga from './gerais/ResultEscLiga';
import ResultEscJogo from './gerais/ResultEscJogo';
import ResultEscAposta from './gerais/ResultEscAposta';
import ResultPrint from './gerais/ResultPrint';
//import Semanas from './pag_1/Semanas';
import Bandeirada from './pag_2/Bandeirada';
import EscolherAposta from './pag_3/EscolherApostas';
import Multiplas from './pag_1/Multiplas';
//import MiniAnalise from './pag_1/MiniAnalise';
import Simular from './pag_1/Simular';
import SemanasApostas from './pag_1/SemanasApostas';
import Torneios from './pag_4/Torneios';
import Login from './pag_4/Login';
import Cadastro from './pag_4/Cadastro';
import CriarTorneio from './pag_4/CriarTorneio';
import BarraMenu from './pag_2/BarraMenu';
function App() {
  const [pesqSelec,setPesqSelec]=useState(false)
  
  const [analise,setAnalise]=useState({grandeza:1,metade:0,c:1,asc:0})
  const [numeroGols,setNumeroGols]=useState(1)
  const [numeroEsc,setNumeroEsc]=useState(8)
  const [keys,setKeys]=useState({confronto:0,estConf:0,time:['',0,0],partida:0,aposta:[0,0,0],result:0,fav:[1,null]})
  const [ordBet,setOrdBet]=useState(2)
  const [pagina,setPagina]=useState(1)
  const [bandeira,setBandeira]=useState(JSON.parse(localStorage.getItem('bandeira'))||'bra1')
  const [swit,setSwit]=useState(true)
  const [swit2,setSwit2]=useState(true)
  const [swit3,setSwit3]=useState(true)
  const [pageBet,setPageBet]=useState(1)

  const [campo,setCampo]=useState('c1')
  const [handicap,setHandicap]=useState(0)
  const [estadia,setEstadia]=useState(0)
  const [metade,setMetade]=useState(0)
  const [toggle,setToggle]=useState(true)

  const [escoAnalise,setEscoAnalise]=useState(true)
  const [queryResult,setQueryResult]=useState({ev:0,tipos:[1,2,6,7,8],camps:ligas.paths})
  const [resultados,setResultados]=useState(false)

  const [showingOdds,setShowingOdds]=useState(true)
  const [pageFavoritas,setPageFavoritas]=useState(1)
  const [contEstrela,setContEstrela]=useState(0)

  const [postResultado,setPostResultado]=useState([])

  const [menuando,setMenuando]=useState(false)
  const [procurando,setProcurando]=useState(false)
  const [bandeirando,setBandeirando]=useState(false)
  const [apostando,setApostando]=useState(false)

  const [miniAnalise,setMiniAnalise]=useState(false)
  const [miniEspera,setMiniEspera]=useState(false)
  const [selecionado,setSelecionado]=useState(false)
  const [primeiraVez,setPrimeiraVez]=useState(true)
  const [combNova,setCombNova]=useState(false)

  const [a_listaAnalise,seta_listaAnalise]=useState(false)
  const [a_analise,seta_analise]=useState(false)
  const [a_partidas,seta_partidas]=useState(false)
  const [a_confrontos,seta_confrontos]=useState(false)
  const [menuExpandido,setMenuExpandido]=useState(false)
  const [a_times,seta_times]=useState(false)
  const [a_tabela,seta_tabela]=useState(false)
  const [blur,setBlur]=useState(false)
  const [data,setData]=useState(false)

  const [user,setUser]=useState(false)

  const valorContexto={
    data,setData,
    blur,setBlur,
    menuExpandido,setMenuExpandido,
    a_tabela,seta_tabela,
    a_times,seta_times,
    a_listaAnalise,seta_listaAnalise,
    a_analise,seta_analise,
    a_partidas,seta_partidas,
    a_confrontos,seta_confrontos,
    combNova,setCombNova,
    primeiraVez,setPrimeiraVez,
    miniEspera,setMiniEspera,
    miniAnalise,setMiniAnalise,
    apostando,setApostando,
    selecionado,setSelecionado,
    procurando,setProcurando,
    bandeirando,setBandeirando,
    menuando,setMenuando,
    postResultado,setPostResultado,
    pesqSelec,setPesqSelec,
    contEstrela,setContEstrela,
    showingOdds,setShowingOdds,
    queryResult,setQueryResult,
    resultados,setResultados,
    ordBet,setOrdBet,
    keys,setKeys,
    analise,setAnalise,
    numeroGols,setNumeroGols,
    numeroEsc,setNumeroEsc,
    pagina,setPagina,
    bandeira,setBandeira,
    campo,setCampo,
    handicap,setHandicap,
    estadia,setEstadia,
    metade,setMetade,
    toggle,setToggle,
    escoAnalise,setEscoAnalise,
    swit,setSwit,
    swit2,setSwit2,
    swit3,setSwit3,
    pageFavoritas,setPageFavoritas,
    pageBet,setPageBet,

    user,setUser,
  }
  
   useEffect(()=>{
    localStorage.setItem('bandeira', JSON.stringify(bandeira))
   },[bandeira])   
  return (
    <div className="App">
      <DarkContext.Provider value={valorContexto}>
        <BrowserRouter>
        <Margem>
          <Tudo>
            <Menu/>
            {menuando?<BarraMenu />:<></>}
            {blur?<Blur onClick={()=>setBlur(false)}/>:<></>}
            {procurando?<Procurar />:<></>}
            {bandeirando?<Bandeirada fechar={()=>setBandeirando(false)} />:<></>}
            <Conteudo  >
              <Routes>
                <Route path='/lista-analise' element={<ListaAnalise/>}/>
                <Route path='/analise' element={<JogoFuturo/>}/>
                <Route path='/partidas' element={<Jogo />}/>
                <Route path='/confrontos' element={<JogoFuturo principal={true}/>}/>
                <Route path='/' element={<ListaPartidas/>}/>
                
                <Route path='/odds' element={<Resultados />}/>
                {/*<Route path='/odds-semanas' element={<Semanas />}/>*/}
                <Route path='/odds-semanas-apostas' element={<SemanasApostas />}/>
                <Route path='/favoritas' element={<Favoritas />}/>
                <Route path='/simular' element={<Simular />}/>
                <Route path='/multiplas' element={<Multiplas />}/>

                <Route path='/torneio' element={<Torneios />}/>
                <Route path='/torneios' element={<Torneios />}/>
                <Route path='/criar-torneio' element={<CriarTorneio />}/>
                <Route path='/login' element={<Login />}/>
                <Route path='/cadastro' element={<Cadastro />}/>

                <Route path='/escolher-tabela' element={<EscolherTabela />}/>
                <Route path='/tabelas' element={<BuscarTabela />}/>
                <Route path='/classificacao' element={<Classif />}/>
                <Route path='/escolher-time' element={<EscolherTime />}/>
                <Route path='/times' element={<Time />}/>


                <Route path='/printresultado/:liga' element={<ResultPrint/>} />
                <Route path='/postresultado/:liga/:manvis' element={<ResultEscAposta/>} />
                <Route path='/postresultado/:liga' element={<ResultEscJogo/>} />
                <Route path='/postresultado' element={<ResultEscLiga/>} />
                <Route path='/criarposts' element={<CriarEscLiga/>} />
                <Route path='/criarposts/:liga' element={<CriarEscJogo/>} />
                <Route path='/criarposts/:liga/:manvis' element={<CriarEscAposta/>} />
                <Route path='/criarposts/:liga/:manvis/:infovalor' element={<CriarPrint/>} />
              </Routes>
            </Conteudo>
          </Tudo>
        </Margem>
        
        
        </BrowserRouter>
      </DarkContext.Provider>
    </div>
  );
}

export default App;
const Blur=styled.div`
background-color:rgb(0,0,0,0.5);
height:calc(100vh - 64px);
width:100vw;
max-width:400px;
display:flex;z-index:29;
flex-direction:column;
align-items:center;
position:fixed;top:64px;
`
const Margem=styled.div`
display:flex;
background-color:var(--verdeMargem);
width:100%;
height:100%;
justify-content:center;
`

const Conteudo=styled.div`
flex-direction:column;
width:100vw;
max-width:400px;
height:calc(100vh - 64px);
`
const Tudo=styled.div`
background-color:white;;
height:100vh;
width:100vw;
max-width:400px;
display:flex;
flex-direction:column;
align-items:center;
position:relative;
button{
  display:flex;
  align-items:center;
  border:0;
  cursor:pointer;
  :active{
    box-shadow: inset 3px 3px 5px rgba(0, 0, 0, 0.2);
    }
}
div{
  display:flex;
  align-items:center;
}
-webkit-tap-highlight-color: transparent;
`
/*
button{
  border:0;
  cursor:pointer;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2);
  :active{
    box-shadow: inset 3px 3px 5px rgba(0, 0, 0, 0.2);
  }
}
*/