import styled from "styled-components"
import logoBranca from '../gerais/logoBranca.png'
import logoVerde from '../gerais/logoVerde.png'

import ReactLoading from 'react-loading';

export default function Loader({branca,pequena}){
    return(
        <Tudo pequeno={pequena}>
            <Holder>
                <img src={branca?logoBranca:logoVerde}/>
                <ReactLoading type={'spin'} color={branca?'white':'#008037'} height={pequena?100:180} width={pequena?100:180} />
            </Holder>
        </Tudo>
    )
}
const Tudo=styled.div`
width:100%;height:100%;
flex-direction:column;
justify-content:space-evenly;
img{
    height:${props=>props.pequeno?50:90}px;
    position:absolute;top:${props=>props.pequeno?27:48}px;
    border-radius:${props=>props.pequeno?0:0}px;
}
h1{
    max-width:400px;width:90%;font-size:20px;
    line-height:24px;color:${props=>props.color}
}
`
const Holder=styled.div`
position:relative;
justify-content:center;
`