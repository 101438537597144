import { useContext, useState } from "react";
import { useEffect } from "react";
import styled from "styled-components";
import DarkContext from "../gerais/DarkContext";
import ListaTime from "../pag_2/ListaTime";
import Loader from "../pag_2/Loader";
import ListaJogosTime from "./ListaJogosTime";
import { useNavigate, useParams } from "react-router-dom";
import { getTime } from "../api";
import Escudo from "../pag_2/Escudo";
import { ligas } from "../ligas/contexts";

export default function Time(){
    const navigate=useNavigate()
    const {bandeira,a_times,
        swit,setSwit}=useContext(DarkContext)
    const [lista,setLista]=useState(false)
    const [listaJogos,setListaJogos]=useState(false)

    const time=a_times
    useEffect(()=>{if(!time)navigate('/')},[])
    const paths=ligas.paths
    const context=ligas.contexts[paths.indexOf(bandeira)]
    const {listaNomes,listaTimes}=context
    function buscarClube(){
        if(!time)return
        setLista(false)
        const promise=getTime(bandeira,time)
        promise.then(res=>{
            const {partidas,stats}=res.data
            setLista(stats)
            setListaJogos(partidas)
        })
    }
    useEffect(buscarClube,[time])
    return(
        lista?
        <Tudo>
            
            <Topo>
                <Escolha onClick={()=>setSwit(true)}  shown={swit}>ESTATÍSTICAS</Escolha>
                <ImagemNome>
                    <Escudo camp={bandeira} time={time} noClick={true}/>
                    {listaNomes[listaTimes.indexOf(time)]}
                </ImagemNome>
                <Escolha onClick={()=>setSwit(false)}  shown={!swit}>PARTIDAS</Escolha>
            </Topo>
            <Conteudo>
                {swit?<ListaTime nome={bandeira+'-'+time+'-'+time} time={time} lista={lista} />:<ListaJogosTime time={time} context={context} jogos={listaJogos}/>}
            </Conteudo>
        </Tudo>:
        <Loader/>
    )
}
const MaisRecente=styled.section`
display:flex;
flex-direction:column;
align-item:flex-start;
font-size:20px;
p{font-size:11px;}
position:absolute;bottom:-15px;left:0;
color:#545454;
`
const Conteudo=styled.div`
position:relative;
height:80%;width:100%;
justify-content:center;
`
const Topo=styled.div`
position:relative;
max-width:400px;
height:20%;width:100%;
justify-content:space-evenly;
`
const ImagemNome=styled.div`
height:100%;margin:5px;width:20%;
position:relative;flex-direction:column;
color:#545454;
justify-content:space-evenly;;
img{
    z-index:1;
    height:55%;
}
aside{display:none};
:hover{
    aside{padding:5px;background-color:black;border-radius:5px;
        display:block;font-size:14px;color:white;cursor:pointer;
        position:absolute;left:center;z-index:2;bottom:0;
    }
}
`
const Tudo=styled.div`
background-color:white;
width:94%;flex-direction:column;
height:calc(100% - 0px);
flex-direction:column;
justify-content:space-evenly;
`
const Escolha=styled.div`cursor:pointer;
background-color:${props=>props.shown?'#e5e5e5':'transparent'};
font-size:12px;
justify-content:center;color:#545454;
width:85px;height:50px;
h1{margin-left: auto;margin-right: auto;font-size:12px;
    small{font-size:12px;}
};
border-radius:5px;
:active{
    box-shadow: inset 3px 3px 5px rgba(0, 0, 0, 0.2);
    }
`