import ama from './escudos/ama.png'
import amg from './escudos/amg.png'
import ava from './escudos/ava.png'
import bot from './escudos/bot.png'
import bru from './escudos/bru.png'
import cea from './escudos/cea.png'
import cha from './escudos/cha.png'
import cor from './escudos/cor.png'
import crb from './escudos/crb.png'
import goi from './escudos/goi.png'
import gua from './escudos/gua.png'
import itu from './escudos/itu.png'
import mir from './escudos/mir.png'
import nov from './escudos/nov.png'
import ope from './escudos/ope.png'
import pay from './escudos/pay.png'
import pon from './escudos/pon.png'
import san from './escudos/san.png'
import spo from './escudos/spo.png'
import vil from './escudos/vil.png'

  const contextBra2={
    listaNomes:['Amazonas FC','América MG','Avaí','Botafogo SP','Brusque','Ceará','Chapecoense','Coritiba','CRB','Goiás',
    'Guarani','Ituano','Mirassol','Novorizontino','Operário','Paysandu','Ponte Preta','Santos','Sport Recife','Vila Nova'],
    listaTimes:['ama','amg','ava','bot','bru','cea','cha','cor','crb','goi',
                'gua','itu','mir','nov','ope','pay','pon','san','spo','vil'],
    listaEscudos:[ama,amg,ava,bot,bru,cea,cha,cor,crb,goi,gua,itu,mir,nov,ope,pay,pon,san,spo,vil],
    path:'bra2'
  }
export default contextBra2