import { useContext } from "react"
import DarkContext from "./DarkContext"
import styled from "styled-components"
import Apostinha from "../pag_1/Apostinha"
import { useNavigate } from "react-router-dom"
export default function ComoTaFicando(){
    const {
        postResultado,setPostResultado
    }=useContext(DarkContext)
    const navigate=useNavigate()
    return(
        <Tudo>
            <Cabec>
                <p>como ta ficando:</p>
                <button onClick={()=>navigate(`/printresultado/${postResultado[0].camp}`)}>PRONTO</button>
            </Cabec>
            <List>
            {postResultado.map(ap=><Apostinha apo={ap}/>)}
            </List>
            
        </Tudo>
    )
}
const Cabec=styled.div`
height:30px;
justify-content:space-between;
button{border-radius:5px;height:25px;width:80px;border:0;background-color:orange;}
`
const List=styled.div`
flex-direction:column;
overflow:hidden;
overflow-y:scroll;
  ::-webkit-scrollbar {width:12px}
  ::-webkit-scrollbar-thumb {background: #00702c; border-radius:5px;}
  ::-webkit-scrollbar-thumb:hover {background: #006028;}

`
const Tudo=styled.div`position:relative;
p{font-size:20px;margin-bottom:15px;}
height:80%;width:400px;flex-direction:column;

`