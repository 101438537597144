import { useContext, useEffect, useState } from "react"
import styled from "styled-components"
import React from 'react';
import DarkContext from "../gerais/DarkContext";
import Loader from "../pag_2/Loader";
import { ordenarApostas } from "../api";
import Apostinha from "./Apostinha";
import { useParams } from "react-router-dom";
import ListaApostas from "./ListaApostas";
export default function SemanasApostas(){
    const {resultados,ordBet
        }=useContext(DarkContext)
    return(
         <Tudo>
            <ListaApostas paginaInteira={true} apostas={ordBet==2?resultados:ordenarApostas(resultados,ordBet)}/>
        </Tudo>
    )
}

const Tudo=styled.div`
box-sizing:border-box;
background-color:white;
width:100%;
height:100%;
flex-direction:column;
article{
    justify-content:space-between;
    width:94%;height:60px;display:flex;
    align-items:center;
}
aside{max-width:400px;
    display:flex;width:88%;height:48px;
    margin-top:10px;
    justify-content:space-between;
    align-items:center;;
    background-color:;
    color:white;
    box-sizing:border-box;
    padding:5px 0px 5px 0px;
    section{display:flex;width:130px;;align-items:center}
  article{display:flex;width:130px;;align-items:center;justify-content:flex-end}
  }
`