import { useContext, useEffect, useState } from 'react'
import { getAnalise } from '../api'
import styled from 'styled-components'
import Loader from '../pag_2/Loader'
import { useNavigate } from 'react-router-dom'
import DarkContext from '../gerais/DarkContext'

export default function BuscarAnalise({confronto,query,setApDisplay}){
    const {keys,setKeys,setBandeirando,setMiniAnalise,
        miniAnalise,seta_listaAnalise,setData
      }=useContext(DarkContext)
    const {camp,mandante,visitante}=confronto
    const [resultado,setResultado]=useState(false)
    function buscar(){
        setResultado(false)
        const promise=getAnalise(camp,mandante,visitante,query)
        promise.then(res=>{
            const {resp,data}=res.data
            setData(data)
            setResultado(resp)
            setApDisplay(resp[2])
        })
    }
    const navigate=useNavigate()
    useEffect(buscar,[query.valor])
    function inverteC(numeroC){
        if(numeroC==1){return 3}else if(numeroC==3){return 1}else{return 2}
    }
    return(resultado.length>0?
        <Tudo>
            {resultado[0].length==1?
            <Holder umTime={resultado[0].length==1}> 
                <Mostrador  info={resultado[0][0]} handleClick={()=>{setMiniAnalise(false);seta_listaAnalise(`${camp}-${mandante}-0-${query.valor}-${query.c}-${mandante+visitante}`);navigate(`/lista-analise`)}}/>
            </Holder>
            :
            <Holder> 
                    <Mostrador info={resultado[0][0]} handleClick={()=>{setMiniAnalise(false);seta_listaAnalise(`${camp}-${mandante}-0-${query.valor}-${query.c}-${mandante+visitante}`);navigate(`/lista-analise`)}}/>
                    <Mostrador info={resultado[0][1]} handleClick={()=>{setMiniAnalise(false);seta_listaAnalise(`${camp}-${visitante}-0-${query.valor}-${inverteC(query.c)}-${mandante+visitante}`);navigate(`/lista-analise`)}}/>
            </Holder>}
            <Holder menor={true}>
                    <Mostrador frase={'em casa'} info={resultado[1][0]} handleClick={()=>{setMiniAnalise(false);seta_listaAnalise(`${camp}-${mandante}-1-${query.valor}-${query.c}-${mandante+visitante}`);navigate(`/lista-analise`)}}/>
                    <Mostrador frase={'fora de casa'} info={resultado[1][1]} handleClick={()=>{setMiniAnalise(false);seta_listaAnalise(`${camp}-${visitante}-2-${query.valor}-${inverteC(query.c)}-${mandante+visitante}`);navigate(`/lista-analise`)}}/>
            </Holder>
        </Tudo>:
        <Tudo>
            <Loader/>
        </Tudo>
        
    )
}
const Tudo=styled.div`
width:100%;background-color:;
height:210px;
marginTop:25px;
flex-direction:column;
`
const Holder=styled.div`background-color:;
justify-content:${props=>props.umTime?'center':'space-between'};
height:${props=>props.menor?'40%':'50%'};
margin-top:10px;width:100%
`
function Mostrador({info,handleClick,frase}){
    const {texto,chance}=info
    return(
        <Most onClick={handleClick} menor={frase}>
            <p >{frase||texto}</p>
            <Barrinha chance={chance}/>
            
            <h2 >{chance.toFixed(0)}%</h2>
            
        </Most>
    )
}
const Most=styled.button`
height:100%;
width:calc(50% - 5px);
border-radius:12px;
flex-direction:column;
justify-content:center;
background-color:#e0dede;
position:relative;
p{
    max-width:95%;font-size:13px;background-color:;
    min-height:calc(100% - 48px);
    display:flex;flex-direction:column;justify-content:center;
    position:absolute;top:0px;
}
h2{
    position:absolute;bottom:25px;
    font-weight:700;font-size:18px
}
`
function Barrinha({chance}){
    return(
        <Bar >
            {chance>0?<Verde width={chance} />:<></>}
        </Bar>
    )
}
const Bar=styled.div`
border-radius:7.5px;
height:15px;
width:90%;position:absolute;bottom:7px;
background-color:#e26a6a;
`
const Verde=styled.div`
border-radius:7.5px;
border-right:1px solid #969696;
top:0;
left:-0.5px;
box-sizing:border-box;
background-color:#4CD85E;
height:100%;
width:${props=>props.width}%
`