import styled from "styled-components"
import { ligas } from "../ligas/contexts"
import { useParams } from "react-router-dom"
import { getJogosResultado, getPartidas } from "../api"
import { useEffect, useState } from "react"
import { JoguinhoFut } from "../pag_3/JoguinhoFut"
import Loader from "../pag_2/Loader"
import ComoTaFicando from "./ResultComoTaFicando"

export default function ResultEscJogo(){
    const [partidas,setPartidas]=useState(false)
    const {liga}=useParams()
    function buscarPartidas(){
        const promise=getJogosResultado(liga)
        promise.then(res=>{
          setPartidas(res.data)
        })
      }
      useEffect(buscarPartidas,[])
    return (
        <Tudo>
            <Lista>
                {partidas?partidas.map((jog,index)=>(jog.label?<></>:<JoguinhoFut doCriar={2}
                    path={liga} mandante={jog.mandante} visitante={jog.visitante}>
                    </JoguinhoFut>)):<Loader/>}
            </Lista>
            <ComoTaFicando/>
        </Tudo>
    )
}
const Tudo=styled.div`justify-content:center;
position:fixed;width:100vw;height:100vh;background-color:grey;
`
const Lista=styled.div`
height:80%;width:400px;flex-direction:column;
overflow:hidden;
overflow-y:scroll;
  ::-webkit-scrollbar {width:12px}
  ::-webkit-scrollbar-thumb {background: #00702c; border-radius:5px;}
  ::-webkit-scrollbar-thumb:hover {background: #006028;}
`
const Camp=styled.div`border-radius:10px;
img{height:80%;margin-right:5px}
width:100%;height:50px;background-color:white;margin-bottom:10px;
`