import { useContext, useEffect } from "react"
import styled from "styled-components"
import DarkContext from "../gerais/DarkContext"
import { useNavigate } from "react-router-dom"
import Escudo from "../pag_2/Escudo"
import { JoguinhoFut } from "./JoguinhoFut"

export default function ListaJogosTime({time,jogos,context}){
  const {keys,setKeys,seta_confrontos,seta_partidas
    }=useContext(DarkContext)
  const {path}=context
  const navigate=useNavigate()
  useEffect(()=>{
    const item=keys.time
    if(item[0]==time&&item[1]==2)document.getElementById(`time${item[2]}`).scrollIntoView()
  },[])
  return(
      <Tudo >
        {jogos.map((part,index)=>{
          const {sg,mandante,visitante,placar,texto}=part
          return(texto?
            <JoguinhoFut id={`time${index}`} setarKeys={()=>setKeys({...keys,time:[time,2,index]})} path={path} mandante={mandante} visitante={visitante} texto={texto}/>
            :
          <Partidinha id={`time${index}`} color={sg>0?'var(--vitoria)':sg<0?'var(--derrota)':'var(--empate)'}
           onClick={()=>{
            setKeys({...keys,time:[time,2,index]})
            if(placar){
              seta_partidas(`${path}-${mandante}-${visitante}`)
              navigate(`/partidas`)
            }else{
              seta_confrontos(`${path}-${mandante}-${visitante}`)
              navigate(`/confrontos`)
            }
            }} >
            <span>
              <Escudo camp={path} time={mandante} noClick={true}/>
              <p>{placar?placar[0]:''}</p> <ion-icon name="close-outline"></ion-icon><p> {placar?placar[1]:''}</p>
              <Escudo camp={path} time={visitante} noClick={true}/>
            </span>
          </Partidinha> 
        )})}
        <MargemBaixo/>
      </Tudo>
    )
}
const MargemBaixo=styled.div`
min-height:40px;width:50px;background-color:;
`

const Partidinha=styled.button`
margin:0px 0 10px 12px;
min-height:72px;max-height:72px;
width:94%;background-color:${props=>props.color};
justify-content:center;
img{
    height:60%;width:auto
};
span{font-size:24px;ion-icon{font-size:16px}
  display:flex;align-items:center;
  height:100%;width:160px;
  justify-content:space-evenly;
}
border-radius:15px;
p{font-size:20px;font-weight:500;}
ion-icon{margin:0 -5px 0 -5px}
`

const Tudo=styled.div`
padding:5px;border-radius:5px;max-width:400px;
    width:98%;height:100%;box-sizing:border-box;
    flex-direction:column;overflow:hidden;
    overflow-y:scroll;
    ::-webkit-scrollbar {
        width:12px
      }
      ::-webkit-scrollbar-track {
        border-top-right-radius:5px;
        border-bottom-right-radius:5px;
      }
      ::-webkit-scrollbar-thumb {
        background: #e5e5e5; 
        border-radius:5px;
      }
      ::-webkit-scrollbar-thumb:hover {
        background: #cecece; 
      }
`