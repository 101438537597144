import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import DarkContext from '../gerais/DarkContext'
import Loader from '../pag_2/Loader'
import { useNavigate, useParams } from 'react-router-dom'
import { getListaAnalise } from '../api'
import Escudo from '../pag_2/Escudo'

export default function ListaAnalise(){
    const navigate=useNavigate()
    const {analise,a_listaAnalise,seta_partidas
       }=useContext(DarkContext)
    const nome=a_listaAnalise
    useEffect(()=>{if(!nome)navigate('/')},[])
    const lis=nome?nome.split('-'):[0,0,0,0,0,0]
    const [camp,time,estadia,valor,c,manvis]=lis
    const [lista,setLista]=useState(false)
    const [texto,setTexto]=useState('')
    const [textinho,setTextinho]=useState('')
    function buscar(){
        const promise=getListaAnalise(camp,time,{...analise,estadia,valor,c},manvis)
        promise.then(res=>{
            const {resp,titulo,subtitulo}=res.data
            setLista(resp)
            setTexto(titulo)
            setTextinho(subtitulo)
        })
    }
    useEffect(buscar,[])
    
    
    return (
         <Tudo>
            {lista? 
            <Container>
                <Head>
                    <Escudo camp={camp} time={time}/>
                    <h1>{texto}</h1>
                    <MaisRecente>
                        <p>mais recente</p>
                        <ion-icon name="arrow-up-outline"></ion-icon>
                    </MaisRecente>
                </Head>
            <Lista>
                {lista.map(part=>(
                    <Partidinha color={part.sg?'var(--vitoria)':'var(--derrota)'}
                    onClick={()=>{seta_partidas(`${camp}-${part.mandante}-${part.visitante}`);navigate(`/partidas`)}} >
                     <span>
                        <Escudo camp={camp} time={part.mandante} noClick={true}/>
                        <h2>x</h2>
                        <Escudo camp={camp} time={part.visitante} noClick={true}/>
                     </span>
                     <main>
                        <h3>{textinho}:</h3>
                        {analise.grandeza==1?
                            <div>
                                <h4>{part.numero[0]}</h4>
                                <p>x</p>
                                <h4>{part.numero[1]}</h4>
                            </div>:
                            <div><h4>{part.numero}</h4></div>
                            
                        }  
                    </main>
                   </Partidinha> 
                ))}
                <MargemBaixo/>
            </Lista>
            </Container>
            :<Loader/>}
        </Tudo>
    )
}
const MargemBaixo=styled.div`
min-height:40px;width:50px;background-color:;
`
const MaisRecente=styled.section`
display:flex;
flex-direction:column;
align-item:flex-start;
font-size:20px;
p{font-size:11px;}
position:absolute;bottom:-35px;left:5px;;
color:#545454;
`
const Lista=styled.div`
max-width:500px;
height:calc(100% - 0px);
padding:5px;border-radius:5px;
width:98%;box-sizing:border-box;
flex-direction:column;overflow:hidden;
    overflow-y:scroll;
    ::-webkit-scrollbar {
        width:12px
      }
      ::-webkit-scrollbar-track {
        border-top-right-radius:5px;
        border-bottom-right-radius:5px;
      }
      ::-webkit-scrollbar-thumb {
        background: #e5e5e5; 
        border-radius:5px;
      }
      ::-webkit-scrollbar-thumb:hover {
        background: #cecece; 
      }
`
const Partidinha=styled.div`
cursor:pointer;box-sizing:border-box;
margin:0px 0 8px 12px;
min-height:72px;max-height:72px;
width:94%;background-color:#dbdbdb;
justify-content:space-between;
img{
    height:55%;width:auto
};
border:0;
span{
    padding:0 20px 0 20px;
    box-sizing:border-box;
    display:flex;align-items:center;
    height:100%;width:50%;
    justify-content:space-evenly;
    border-top-left-radius:15px;
    border-bottom-left-radius:15px;
}
border-radius:15px;
h2{font-size:15px;background-color:}
main{
    background-color:${props=>props.color};
    display:flex;justify-content:space-evenly;
    width:50%;height:100%;
    border-top-right-radius:15px;
    border-bottom-right-radius:15px;
    h3{
        font-size:14px;
        max-width:70%;display:flex;justify-content:center;
        align-items:center;
    }
    div{
        width:45px;justify-content:space-evenly;
        h4{font-size:22px;font-weight:600;}
        p{font-size:14px;}
    }
}
  :active{
    box-shadow: inset 3px 3px 5px rgba(0, 0, 0, 0.2);
    }
`
const Head=styled.div`
max-width:500px;position:relative;
height:150px;width:100%;
margin:10px 0 20px 0;
flex-direction:column;
justify-content:space-evenly;
img{height:60px;width:60px};
h1{
    max-width:90%;justify-content:center;align-items:center;
    color:#545454;font-size:18px
}
img{
    cursor:pointer;}
`

const Container=styled.div`
flex-direction:column;
height:100%;width:100%;
`
const Tudo=styled.div`
width:100%;height:100%;
background-color:white;
flex-direction:column;
justify-content:space-evenly;
`
