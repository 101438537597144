import { useContext } from 'react'
import styled from 'styled-components'
import DarkContext from '../gerais/DarkContext'

export default function SeletorTabela({comMetade}){
    const {
        estadia,setEstadia,metade,setMetade,
      }=useContext(DarkContext)
    return(
        <Tudo >
            <main>
                <span>
                    <Escolha selected={estadia===0} onClick={()=>setEstadia(0)}>
                        <h1><small>TODAS</small></h1>
                    </Escolha>
                    <Escolha selected={estadia===1} onClick={()=>setEstadia(1)}>
                        <EmCasa  color='12a8a8'><h1>CASA</h1></EmCasa>
                    </Escolha>
                    <Escolha selected={estadia===2} onClick={()=>setEstadia(2)}>
                        <EmCasa color='560ea3'><h1>FORA</h1></EmCasa>
                    </Escolha>
                </span>
                {comMetade?
                <span>
                    <Escolha selected={metade===1} onClick={()=>setMetade(1)}>
                        <h1>1ºT</h1>
                    </Escolha>
                    <Escolha selected={metade===2} onClick={()=>setMetade(2)}>
                        <h1>2ºT</h1>
                    </Escolha>
                    <Escolha selected={metade===0} onClick={()=>setMetade(0)}>
                        <h1><small>JOGO INTEIRO</small></h1>
                    </Escolha>
                </span>:<></>}
            </main>
        </Tudo>
    )
}
const EmCasa=styled.div`
font-size:14px;
justify-content:center;
color:white;
border-radius:3px;
width:40px;height:20px;
background-color:#${props=>props.color};
`
const Escolha=styled.button`
background-color:${props=>props.selected?'#e5e5e5':'transparent'};
font-size:12px;
justify-content:center;color:#545454;
width:55px;height:50px;
h1{
    margin-left: auto;margin-right: auto;font-size:12px;
    small{font-size:12px;}
};
border-radius:5px;

`
const Tudo=styled.div`
width:100%;
height:65px;
width:100%;
justify-content:center;
span{display:flex;}
box-sizing:border-box;
main{
    width:98%;align-items:center;
    display:flex;justify-content:space-between
};

`