import arg from './_bandeiras/arg.png'
import bra from './_bandeiras/bra.png'
import bra2 from './_bandeiras/bra2.png'
import eua from './_bandeiras/eua.png'
import jap from './_bandeiras/jap.png'
import kor from './_bandeiras/cor.png'
import uru from './_bandeiras/uru.png'
import sue from './_bandeiras/sue.png'
import contextEua1 from "./eua1/context";
import contextBra2 from "./bra2/context";
import contextJap1 from "./jap1/context";
import contextArg1 from "./arg1/context";
import contextBra1 from "./bra1/context";
import contextKor1 from './kor1/context';
import contextUru1 from './uru1/context'
import contextSue1 from './sue1/context'
export const ligas={
    contexts:[contextBra1,
        //contextArg1,contextUru1,contextEua1,
        contextBra2,contextJap1,
        //contextKor1,contextSue1
        ],
    paths:['bra1',//'arg1','uru1','eua1',
        'bra2',
        //'jap1',
        //'kor1','sue1'
        ],
    orients:[1,1,1,1,1,1,1,1],
    segundoNome:['Campeonato Brasileiro',
        //'Campeonato Argentino','Campeonato Uruguaio','Campeonato Estadunidense',
        'Campeonato Brasileiro Série-B',
        'Campeonato Japonês',
        //'Campeonato Koreano','Campeonato Sueco'
        ],
    nomes:['Brasil',
        //'Argentina','Uruguai','EUA',
        'Brasil B',
        'Japão',
        //'Korea','Suécia'
        ],
    imagens:[bra,//arg,uru,eua,
        bra2,
        jap
        //,kor,sue
        ],
    cores:[
      '#FEE008,#FEE008,#FEE008,#0B9F42,#0B9F42,#0B9F42',
      '#7CB1E1,#7CB1E1,white,white,#7CB1E1,#7CB1E1',
      'white,#7CB1E1,white,#7CB1E1,white,#7CB1E1',
      '#13339D,#13339D,white,#DF0C0C,white,#DF0C0C',
      '#FEE008,#0B9F42,#FEE008,#0B9F42,#FEE008,#0B9F42',
      'white,white,white,#DF0C0C,white,white',
      'white,white,#13339D,#DF0C0C,white,white',
      '#13339D,#13339D,#FEE008,#FEE008,#13339D,#13339D'
    ]
}

export const _COMB='comb4'
export const _FAV='fav4'

//contexts:[contextIng1,contextIta1,contextAle1,contextFra1,contextEsp1,contextArg1],
//paths:['ing1','ita1','ale1','fra1','esp1'],
//orients:[1,0,1,0,1,1],
//segundoNome:['Campeonato Inglês','Campeonato Italiano','Campeonato Alemão','Campeonato Francês','Campeonato Espanhol','Campeonato Argentino'],
//nomes:['Premier League','Calcio','Bundesliga','Ligue 1','La Liga','Argentino'],
//imagens:[ing,ita,ale,fra,esp,arg],
/*
    cores:[
        'white,white,white,#DF0C0C,white,white',
        '#DF0C0C,#DF0C0C,white,white,#0B9F42,#0B9F42',
        'black,black,#DF0C0C,#DF0C0C,#FEE008,#FEE008',
        '#DF0C0C,#DF0C0C,white,white,#13339D,#13339D',
        '#DF0C0C,#DF0C0C,#FEE008,#FEE008,#DF0C0C,#DF0C0C',
        '#7CB1E1,#7CB1E1,white,white,#7CB1E1,#7CB1E1',
    ]*/
   //'#FEE008,#FEE008,#FEE008,#0B9F42,#0B9F42,#0B9F42', ///brasil