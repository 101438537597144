
import { useContext, useEffect, useState } from "react"
import styled from "styled-components"
import React from 'react';
import DarkContext from "../gerais/DarkContext";
import Loader from "../pag_2/Loader";
import { getApostas } from "../api";
import Apostinha from "./Apostinha";
import { useNavigate } from "react-router-dom";
import { AiOutlineClose, AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import { _COMB, ligas } from "../ligas/contexts";
import moeda from '../gerais/moedaFutbase.png'
import Cab from "./Teclado";
export default function Multiplas(){
    const {setPagina,keys,setKeys,pageFavoritas,setPageFavoritas,
        }=useContext(DarkContext)
        const {paths}=ligas
    const [listaCombs,setListaCombs]=useState(false)
    const [dispo,setDispo]=useState(false)
    
    function buscarCombs(){
        setListaCombs(false)
        const promise=getApostas(2)
        promise.then(res=>{
            let disponivel=100
            let aberto=0
          let resultado=res.data
          const myCombs=JSON.parse(localStorage.getItem(_COMB))||[]
          const combsEmUmaLista=[]
          for(let comb of myCombs){for(let ap of comb.aps){combsEmUmaLista.push(ap)}}
          const final=myCombs.map(comb=>{
            const {aps,valor}=comb
            const apostas=aps.map(apNome=>darApostaApartirDoNome(resultado,apNome))
            let gree=true
            let x=1
            for(let k=0;k<apostas.length;k++){
                x=x*apostas[k].odd
                if(apostas[k].green===0)gree=false
                if(apostas[k].green===null && gree!==false)gree=null
                if(apostas[k].green===undefined && gree!==false && gree!==null)gree=undefined
            }
            const retorno=(valor*x).toFixed(2)
            disponivel-=valor
            if(gree===true){
                disponivel+=parseFloat(retorno)
            }else if(gree!==false){
                //aberto+=parseFloat(retorno)
            }
            return(
            {   gree,retorno,
                odd:x,
                valor:comb.valor,
                apostas
            }
          )})
          setDispo([disponivel/*,aberto*/])
          setListaCombs(final)
        })
      }
    function darApostaApartirDoNome(lista,nome){
        for(let ap of lista){
            if(ap.nome==nome)return ap
        }
    }
    
    function deletarComb(index){
        const list=JSON.parse(localStorage.getItem(_COMB))||[]
        list.splice(index,1)
        localStorage.setItem(_COMB, JSON.stringify(list))
        setKeys({...keys,fav:[2,null]})
        buscarCombs()
    }
    
    useEffect(()=>{
        if(pageFavoritas==2){
            const item=keys.fav
            if(item[0]==2&&listaCombs&&item[1]){
                document.getElementById(`fav${item[1]}`).scrollIntoView()
            }
        }
    },[listaCombs])
    useEffect(buscarCombs,[])
    
    return(
         <Tudo>
            {dispo?<Cab valor={dispo} semAberto={true}/>:<></>}
            {listaCombs?
                listaCombs.length==0?
                <ViewNada>
                <div>
                    <h6>Você não tem apostas simuladas</h6>
                    <h6>Clique no botao "SIMULAR APOSTA" na página de favoritas para criar uma</h6>
                    <ion-icon name="list-circle-outline"></ion-icon>
                </div>
                </ViewNada>
                :
                <Quadro>
                    {listaCombs.map((comb,index)=>{
                        const {apostas,valor,gree,odd,retorno}=comb
                        
                        return(
                            <Bloco color={gree===true?'40dd6c':gree===false?'f27979':''} onProntas={true} 
                            height={85+apostas.length*80}>
                               
                                {apostas.map(apo=>{
                                return(
                                    <Apostinha onBloco={true} setarKeys={()=>setKeys({...keys,fav:[2,index]})} id={`fav${index}`} apo={apo}/>
                                ) 
                                })}
                                <section>
                                    {<h2></h2>}
                                    {gree===undefined?<NoButton noMargin={true} onClick={()=>{deletarComb(index)}} >CANCELAR</NoButton>:<></>}
                                    {odd==1?<></>:<p><small>{odd.toFixed(2)}</small></p>}
                                </section>
                                <Money>
                             <p><img src={moeda}/> <strong>{valor}</strong></p>  
                            <p>RETORNO: <img src={moeda}/><strong> {retorno}</strong></p>
                        </Money>

                            </Bloco>
                    )})}
                    <MargemBaixo/>
                </Quadro>
                :
                <Loader />}
        </Tudo>
    )
}

const Money=styled.div`
height:40px;width:92%;
img{height:30px;}padding:0 7px 0 0;box-sizing:border-box;
justify-content:space-between;
p{font-size:13px;display:flex;align-items:center;strong{font-size:18px;}}
font-weight:500;`
const MargemBaixo=styled.div`
min-height:40px;width:50px;background-color:;
`
const ViewNada=styled.div`
height:calc(100% - 50px);
width:100%;
justify-content:center;
div{width:60%;height:200px;justify-content:space-between;
    flex-direction:column;
}
font-size:18px;line-height:25px;
ion-icon{font-size:60px;}
color:#545454;
`



const Quadro=styled.section`position:relative;
margin-top:5px;
max-width:400px;
height:calc(100% - 50px);
width:100%;
display:flex;
flex-direction:column;
align-items:flex-start;
overflow:hidden;
overflow-y:scroll;
::-webkit-scrollbar {
    width:12px
  }
  ::-webkit-scrollbar-track {
    border-top-right-radius:5px;
    border-bottom-right-radius:5px;
  }
  ::-webkit-scrollbar-thumb {
    background: #e5e5e5; 
    border-radius:5px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #cecece; 
  }`

const HolderLoader=styled.div`height:calc(100% - 180px)`

const Tudo=styled.div`
background-color:white;
width:100%;flex-direction:column;
height:calc(100% - 0px);

article{display:flex;width:94%;max-width:400px;justify-content:space-between;}
`
const Bloco=styled.div`max-width:400px;
margin:${props=>props.onProntas?'0px 0 20px 12px':'10px 0 0 0'};
width:${props=>props.onProntas?94:100}%;
display:flex;flex-direction:column;align-items:center;
justify-content:space-evenly;
position:relative;min-height:${props=>props.height}px;
background-color:#${props=>props.color||'7cb0ff'};
color:black;
border-radius:15px;
display:flex;
align-items:center;
justify-content:space-evenly;
section{
    width:94%;display:flex;justify-content:space-between;
    box-sizing:boxder-box;padding:0 10px 0 10px;
        p{display:flex;width:45px;
            font-size:18px;font-weight:600;
        height:30px;align-items:center;
        box-sizing:border-box
        }
        h6{height:30px;
            display:flex;align-items:center;justify-content:center;
        }
        h2{
            font-size:20px;width:45px;
            small{font-size:11px}
            margin-bottom:12px;
        }
        h4{background-color:#001c47;color:white;
            position:absolute;bottom:0;left:0;
            height:18px;padding-left:10px;
            justify-content:center;
            display:flex;align-items:center;
            font-size:14px;width:45px;
        border-bottom-left-radius:16px;
        border-top-right-radius:16px;
        
        }
        p{small{font-weight:700}}
}

`

const NoButton=styled.div`
cursor:pointer;background-color:#f74747;display:flex;
justify-content:center;align-items:center;color:white;
font-size:12PX;height:30px;border-radius:15px;
width:80px;margin-right:${props=>props.noMargin?0:20}px;
`
