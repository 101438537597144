import { useContext, useEffect } from "react";
import styled from "styled-components";
import DarkContext from "../gerais/DarkContext";
import { useNavigate } from "react-router-dom";
import Escudo from "../pag_2/Escudo";
import { ligas } from "../ligas/contexts";
import { useState } from "react";
import { getPodeConvidar, getTorneios } from "../api";

export default function CriarTorneio(){
    const {user}=useContext(DarkContext)
    const navigate=useNavigate()
    const [nome,setNome]=useState('')
    const [buscado,setBuscado]=useState('')
    const [convidados,setConvidados]=useState([user])
    function convidar(){
        if(!buscado)return
        const promise=getPodeConvidar(buscado)
        promise.then(res=>{
            const novo=res.data
            setConvidados([...convidados,novo])
            setBuscado('')
        })
    }
    function postar(){
        const promise=getTorneios(user.id)
        promise.then(res=>{
        })
    }
    return(
        <Tudo>
            <Titulo>
                Criar torneio
            </Titulo>
             <input id="searchField"
              value={nome} 
              onChange={e=>{setNome(e.target.value)}} 
            placeholder='nome do torneio'></input>
            <Holder>
                <input
                value={buscado} 
                onChange={e=>{setBuscado(e.target.value)}} 
                placeholder='nome do amigo'></input>
                <button onClick={convidar}>
                    Convidar
                </button>
            </Holder>
            
            <Quadrinho>
                {convidados.map(us=><Pessoa>
                    <p>{us.nome}</p>
                </Pessoa>)}
            </Quadrinho>
            <Postar onClick={postar}>
                Criar
            </Postar>
        </Tudo>
    )
}
const Pessoa=styled.div`
justify-content:center;
margin:10px 0 0 0;flex-direction:column;border-radius:10px;
height:40px;background-color:#bcbcbc;width:200px;
`
const Holder=styled.div`
button{
cursor:pointer;
justify-content:center;
background-color:#22b736;
height:50px;width:100px;
border-radius:10px;
color:white;
margin-top:20px;}
`
const Titulo=styled.div`
justify-content:center;
height:50px;width:150px;
border-radius:10px;
color:#008037;font-size:25px;
margin-top:20px;
`
const Postar=styled.div`
cursor:pointer;
justify-content:center;
background-color:#22b736;
height:50px;width:100px;
border-radius:10px;
color:white;
margin-top:20px;
`
const Quadrinho=styled.div`
height:200px;width:98%;
overflow:hidden;
overflow-y:scroll;
::-webkit-scrollbar {
    width: 15px;
  }
  ::-webkit-scrollbar-track {
    border-radius:7.5px;
  }
  ::-webkit-scrollbar-thumb {
    background:#EFEFEF; 
    border-radius:7.5px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background:#cecece;
  }
    flex-direction:column;
`
const Tudo=styled.div`
background-color:white;
width:100%;
height:calc(100% - 0px);
max-width:370px;
box-sizing:border-box;
height:100%;width:98%;
input{
    font-size:14px;padding-left:10px;box-sizing:border-box;
    height:40px;width:calc(100% - 15px);max-width:400px;
    border-radius:10px;
    border:1px solid green;
    margin-top:20px;
}

overflow:hidden;
overflow-y:scroll;
::-webkit-scrollbar {
    width: 15px;
  }
  ::-webkit-scrollbar-track {
    border-radius:7.5px;
  }
  ::-webkit-scrollbar-thumb {
    background:#EFEFEF; 
    border-radius:7.5px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background:#cecece;
  }
    flex-direction:column;

`