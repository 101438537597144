import { useNavigate } from "react-router-dom";
import { ligas } from "../ligas/contexts";
import DarkContext from "../gerais/DarkContext";
import { useContext } from "react";

export default function Escudo({camp,time,noClick}){
    const {keys,setKeys,setBandeira,setMiniAnalise,
        miniAnalise,seta_times
      }=useContext(DarkContext)
    const navigate=useNavigate()
    const paths=ligas.paths
    const context=ligas.contexts[paths.indexOf(camp)]
    const {listaEscudos,listaTimes}=context
    return (
        <img 
            onClick={()=>{if(!noClick){seta_times(`${time}`);navigate(`/times`);setMiniAnalise(false);setBandeira(camp)}}}
            src={listaEscudos[listaTimes.indexOf(time)]}
            />
    )
}