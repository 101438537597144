import { useContext, useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import styled from 'styled-components'
import DarkContext from '../gerais/DarkContext'
import Loader from './Loader'
import { getClassif } from '../api';
import Escudo from './Escudo';
import { JoguinhoFut } from '../pag_3/JoguinhoFut';
export default function Classif(){
    const navigate=useNavigate()
    const {bandeira,keys,setKeys,seta_confrontos,seta_partidas,
        swit2,setSwit2}=useContext(DarkContext)
    const [classif,setClassif]=useState(false)
    const [partidas,setPartidas]=useState(false)
    const campos =true||bandeira=='bra1'||bandeira=='bra2'? [
        ['Pts','Vit','SG','GM','PJ'],['Pontos','Vitórias','Saldo de gols','Gols marcados','Partidas jogadas']
    ]:
    [
        ['Pts','SG','GM','Vit','PJ'],['Pontos','Saldo de gols','Gols marcados','Vitórias','Partidas jogadas']
    ]
    function buscarClassificacao(){
        setClassif(false)
        const promise = getClassif(bandeira)
        promise.then(res=>{
            const {classif,parts}=res.data
            setClassif(classif)
            setPartidas(parts)
            console.log(classif)
        })
        
    }
    useEffect(buscarClassificacao,[bandeira])
    useEffect(()=>{
        if(!swit2&&partidas)document.getElementById(`partida${keys.partida}`).scrollIntoView()
      },[partidas])
    return(
        classif?
        <Tudo>
            <main>
                <Escolha onClick={()=>setSwit2(true)}  shown={swit2}>TABELA</Escolha>
                <Escolha onClick={()=>setSwit2(false)}  shown={!swit2}>PARTIDAS</Escolha>
            </main>
           
            <Resto>
                {swit2?<Quadro >
                    <Linha color={'#afafaf'} first={true}>
                        <Campo width={'25px'}></Campo>
                        <Campo width={'45px'}></Campo>
                        {campos[0].map((camp,index)=>(
                            <Campo width={'calc(20% - 15px)'}>
                                <Bolinha >{camp}</Bolinha>
                            </Campo>
                        ))}
                    </Linha>
                    {classif.map((est,index)=>{
                        const {pontos,vitorias,saldoGols,golsPro,partidas}=est
                        const ordem=bandeira=='bra1'||bandeira=='bra2'?[
                            pontos,vitorias,saldoGols,golsPro,partidas
                        ]:[
                            pontos,saldoGols,golsPro,vitorias,partidas
                        ]
                        return(
                        <Linha color={index%2?'white':'#efefef'}>
                            <h1>{index+1}º</h1>
                            <HolderImg><Escudo camp={bandeira} time={est.time}/></HolderImg>
                            <p>{ordem[0]}</p>
                            <p>{ordem[1]}</p>
                            <p>{ordem[2]}</p>
                            <p>{ordem[3]}</p>
                            <p>{ordem[4]}</p>
                        </Linha>
                    )})}
                </Quadro>:
                <Partidas>
                    {partidas.map((part,index)=>{
                    const {mandante,visitante,placar,texto}=part
                    return(texto?
                        <JoguinhoFut id={`partida${index}`} setarKeys={()=>setKeys({...keys,partida:index})} path={bandeira} mandante={mandante} visitante={visitante} texto={texto}/>
                        :
                        <Partidinha id={`partida${index}`} onClick={()=>{
                            setKeys({...keys,partida:index})
                            if(placar){
                                seta_partidas(`${bandeira}-${mandante}-${visitante}`)
                                navigate(`/partidas`)
                              }else{
                                seta_confrontos(`${bandeira}-${mandante}-${visitante}`)
                                navigate(`/confrontos`)
                              }
                        }}>
                             <span>
                                <Escudo camp={bandeira} time={mandante} noClick={true}/>
                                <p>{placar?placar[0]:''}</p> <ion-icon name="close-outline"></ion-icon><p> {placar?placar[1]:''}</p>
                                <Escudo camp={bandeira} time={visitante} noClick={true}/>
                            </span>
                            
                        </Partidinha> 
                    )})}
                    <MargemBaixo/>
                </Partidas>}
            </Resto>
        </Tudo>:
        <Loader/>
    )
}
const MargemBaixo=styled.div`
min-height:40px;width:50px;background-color:;
`
const HolderImg=styled.div`
min-height:45px;
min-width:45px;
justify-content:center;
`
const Escolha=styled.div`cursor:pointer;
background-color:${props=>props.shown?'#e5e5e5':'transparent'};
font-size:12px;
justify-content:center;
color:#545454;
border:0;
width:49%;
height:50px;
h1{
    margin-left: auto;margin-right: auto;font-size:12px;
    small{font-size:12px;}
}
margin:10px 0 0 0;
border-radius:5px;
:active{
    box-shadow: inset 3px 3px 5px rgba(0, 0, 0, 0.2);
    }
`
const Resto=styled.div`
margin:10px 0 0 0;
height:calc(100% - 70px);
width:94%;
justify-content:space-evenly;


`
const Partidinha=styled.button`
height:70px;
width:94%;
background-color:#d8d8d8;
justify-content:space-evenly;
margin:0 0 10px 12px;
border-radius:5px;
font-size:15px;
span{
    img{max-height:60%}
    display:flex;align-items:center;
    height:72px;width:150px;
    justify-content:space-evenly;
}
img{height:80%;width:auto}
border-radius:15px;
p{;font-size:18px}
h2{font-size:15px};
`
const Campo=styled.div`
width:${props=>props.width};
justify-content:center;
`
const Bolinha=styled.div`
max-width:50px;
width:70%;
height:30px;
border-radius:5px;
background-color:var(--vitoria);
justify-content:center;
`
const Tudo=styled.div`
background-color:white;
max-width:400px;
width:100%;
height:100%;
flex-direction:column;
main{
    justify-content:space-between;
    width:94%;height:60px;display:flex;
    align-items:center;
}
`
const Partidas=styled.div`
width:100%;
flex-wrap:wrap;
justify-content:space-between;
height:100%;
overflow:hidden;overflow-y:scroll;max-width:1000px;
::-webkit-scrollbar {
    width: 15px;
  }
  ::-webkit-scrollbar-track {
    border-radius:7.5px;
  }
  ::-webkit-scrollbar-thumb {
    background:#EFEFEF; 
    border-radius:7.5px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background:#cecece;
  }
`
const Quadro=styled.div`
border:2px solid #afafaf;
box-sizing:border-box;
height:calc(100% - 20px);
width:98%;
flex-direction:column;
border-radius:10px;
overflow:hidden;overflow-y:scroll;max-width:1000px;
::-webkit-scrollbar {
    width: 15px
  }
  ::-webkit-scrollbar-track {
    border-radius:7.5px;
  }
  ::-webkit-scrollbar-thumb {
    background:#EFEFEF; 
    border-radius:7.5px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background:#cecece;
  }
   ;p{font-size:15px;font-weight:300}
  
}  
`
const Linha=styled.div`
img{cursor:pointer;height:40px;width:auto;}
p{
    width:17.3%;display:flex;justify-content:center;align-items:center;
};
h1{
    width:30px;display:flex;justify-content:center;align-items:center;
};
min-height:45px;
background-color:${props=>props.color};
width:100%;
justify-content:space-between;
position:${props=>props.first?'sticky;top:0;':''};
`