import { useContext, useEffect } from "react";
import styled from "styled-components";
import DarkContext from "../gerais/DarkContext";
import { useNavigate } from "react-router-dom";
import Escudo from "../pag_2/Escudo";
import { ligas } from "../ligas/contexts";

export default function EscolherTime(){
    const {bandeira,seta_times}=useContext(DarkContext)
    const paths=ligas.paths
    const context=ligas.contexts[paths.indexOf(bandeira)]
    const {listaEscudos,listaTimes,listaNomes}=context
    const navigate=useNavigate()
    return(
        <Tudo>
            {listaTimes.map((time,index)=>(
                <ImagemNome menor={false} color={listaEscudos[index].length==7?listaEscudos[index]:0}>
                    <Escudo camp={bandeira} time={time}/>
                    <aside onClick={()=>{seta_times(`${time}`);navigate(`/times`)}}>{listaNomes[index]}</aside>
                </ImagemNome>
            ))}
        </Tudo>
    )
}
const ImagemNome=styled.div`
height:80px;;margin:15px 5px 5px 5px;width:${props=>props.menor?16:20}%;
position:relative;background-color:;box-sizing:border-box;
justify-content:flex-start;flex-direction:column;color:#545454;
ion-icon{font-size:55px;color:${props=>props.color};cursor:pointer}
img{z-index:1;
    cursor:pointer;width:50px;
}
    aside{;border-radius:5px;
        background-color:;;font-size:12px;;cursor:pointer;height:25px;
        position:absolute;left:center;z-index:2;bottom:0;
    }
`
const Tudo=styled.article`display:flex;
background-color:white;
width:100%;
height:calc(100% - 0px);
max-width:370px;
box-sizing:border-box;
flex-wrap:wrap;
justify-content:space-between;align-items:flex-start;
overflow:hidden;overflow-y:scroll;max-width:1000px;
::-webkit-scrollbar {
    width: 15px;
  }
  ::-webkit-scrollbar-track {
    border-radius:7.5px;
  }
  ::-webkit-scrollbar-thumb {
    background:#EFEFEF; 
    border-radius:7.5px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background:#cecece;
  }
`