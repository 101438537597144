
import juv from './escudos/juv.png'
import cap from './escudos/cap.png'
import cam from './escudos/cam.png'
import bot from './escudos/bot.png'
import bra from './escudos/bra.png'
import cor from './escudos/cor.png'
import cri from './escudos/cri.png'
import cui from './escudos/cui.png'
import fla from './escudos/fla.png'
import flu from './escudos/flu.png'
import fort from './escudos/for.png'
import ago from './escudos/ago.png'
import int from './escudos/int.png'
import pal from './escudos/pal.png'
import vit from './escudos/vit.png'
import sao from './escudos/sao.png'
import bah from './escudos/bah.png'
import cru from './escudos/cru.png'
import gre from './escudos/gre.png'
import vas from './escudos/vas.png'

const contextBra1={
  listaNomes:['Atlético GO','Athlético PR','Atlético MG','Criciúma','Bahia','Botafogo','Bragantino','Corinthians','Cruzeiro','Cuiabá','Flamengo','Fluminense','Fortaleza','Grêmio','Internacional','Juventude','Palmeiras','São Paulo','Vasco da Gama','Vitória'],
  listaTimes:['ago','cap','cam','cri','bah','bot','bra','cor','cru','cui','fla','flu','for','gre','int','juv','pal','sao','vas','vit'],
  listaEscudos:[ago,cap,cam,cri,bah,bot,bra,cor,cru,cui,fla,flu,fort,gre,int,juv,pal,sao,vas,vit],
  path:'bra1'
}
export default contextBra1
