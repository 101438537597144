import DarkContext from "../gerais/DarkContext"

import styled from "styled-components"
import { useContext } from "react"
import { useState } from "react"
import { ligas } from "../ligas/contexts"

export default function Tipada({fechar}){
    const {
        queryResult,setQueryResult
    }=useContext(DarkContext)
    const {paths,imagens,nomes}=ligas
    const [nova,setNova]=useState(queryResult.tipos)
    const [novaCamps,setNovaCamps]=useState(queryResult.camps)
    return(
        <Tudo>
            <Quadro>
                <h2>Tipos de apostas</h2>
                <Tipos>
                    {[
                        [1,'Vitórias','calendar-clear'],
                        [2,'Gols','football'],
                        [6,'Escanteios','golf']
                    ].map((tipo,index)=>
                        <Tipo tipo={tipo} nova={nova} setNova={setNova} />
                    )}
                </Tipos>
                <h2>Ligas selecionadas</h2>
                <Camps>
                    {paths.map((path,index)=>
                        <Camp camp={[path,imagens[index],nomes[index]]} nova={novaCamps} setNova={setNovaCamps} />
                    )}
                    <TipFalso/>
                </Camps>
                <Botao onClick={()=>{
                    setQueryResult({...queryResult,tipos:nova,camps:novaCamps})
                    fechar()
                }}><p>OK</p>
                </Botao>
            </Quadro>
        </Tudo>
    )
}
function Tipo({tipo,nova,setNova}){
    return(
        <Tip selected={nova.includes(tipo[0])}
         onClick={()=>{
            const c2=tipo[0]==2
            if(nova.includes(tipo[0])){
                if(nova.length==1||(nova[0]==2&&nova[1]==7&&nova[2]==8&&!nova[3]))return
                const newo=[]
                for(let item of nova){
                    if(item!=tipo[0]&&(c2?item!=7:true)&&(c2?item!=8:true))newo.push(item)
                }
                setNova(newo)
            }else{
                const no=c2?[...nova,tipo[0],7,8]:[...nova,tipo[0]]
                setNova(no)
            }
         }} >
            <ion-icon name={tipo[2]+'-outline'}></ion-icon>
            <h1>{tipo[1]}</h1>
        </Tip>
    )
}
function Camp({camp,nova,setNova}){
    return(
        <Tip comMargem={true} selected={nova.includes(camp[0])}
         onClick={()=>{
            if(nova.includes(camp[0])){
                if(nova.length==1)return
                const newo=[]
                for(let item of nova){
                    if(item!=camp[0])newo.push(item)
                }
                setNova(newo)
            }else{
                setNova([...nova,camp[0]])
            }
         }} >
            <img src={camp[1]} />
            <h1>{camp[2]}</h1>
        </Tip>
    )
}
const Camps=styled.section`
display:flex;align-items:flex-start;
flex-direction:row;justify-content:space-evenly;
height:250px;background-color:;width:100%;
`
const Tipos=styled.div`
width:100%;justify-content:space-evenly;
`
const Botao=styled.div`
cursor:pointer;
justify-content:center;
background-color:#22b736;
height:50px;width:100px;
border-radius:10px;
color:white;
position:absolute;
bottom:20px;
`
const Quadro=styled.div`
h2{
    font-size:17px;font-weight:600;
    height:40px;display:flex;align-items:center;
    width:92%;padding-top:10px;box-sizing:border-box;
}
box-sizing:border-box;
display:flex;align-items:flex-start;
flex-direction:column;justify-content:flex-start;
border-radius:30px;
position:absolute;top:10%;
background-color:white;height:80%;width:320px;
`
const Tip=styled.button`
ion-icon{font-size:28px}
background-color:${props=>props.selected?'#a5c0e5':'transparent'};
color:${props=>props.selected?'#545454':'#545454'};
font-size:12px;
justify-content:space-evenly;
height:70px;width:28%;flex-direction:column;
h1{
    font-size:14px;
};
img{height:36px}
border-radius:10px;
`
const TipFalso=styled.div`
height:70px;width:28%;
`
const Tudo=styled.div`
position:fixed;
;z-index:21;
width:100vw;
height:100vh;
background-color:rgb(0,0,0,0.75);
max-width:700px;
top:0;
flex-direction:column;
`
