import alb from './escudos/alb.png'
import ant from './escudos/ant.png'
import avi from './escudos/avi.png'
import cer from './escudos/cer.png'
import con from './escudos/con.png'
import fro from './escudos/fro.png'
import gam from './escudos/gam.png'
import jub from './escudos/jub.png'
import kas from './escudos/kas.png'
import kyo from './escudos/kyo.png'
import mac from './escudos/mac.png'
import nag from './escudos/nag.png'
import sag from './escudos/sag.png'
import san from './escudos/san.png'
import sho from './escudos/sho.png'
import tok from './escudos/tok.png'
import ura from './escudos/ura.png'
import ver from './escudos/ver.png'
import vis from './escudos/vis.png'
import yok from './escudos/yok.png'


const contextJap1={
  listaNomes:['Albirex Nigata','Kashiwa Antlers','Avispa','Cerezo Osaka','Consadole Sapporo','Frontale','Gamba Osaka','Júbilo Iwata','Kashiwa Reysol','Kyoto Sanga',
        'Machida Zelvia','Nagoya Grampus','Sagan Tosu','Sanfrecce Hiroshima','Shonan','FC Tokyo','Urawa Reds','Tokyo Verdy','Vissel Kobe','Yokohama Marinos'],
  listaTimes:[ 'alb','ant','avi','cer','con','fro','gam','jub','kas','kyo',
    'mac','nag','sag','san','sho','tok','ura','ver','vis','yok',],
  listaEscudos:[alb,ant,avi,cer,con,fro,gam,jub,kas,kyo,mac,nag,sag,san,sho,tok,ura,ver,vis,yok],
  path:'jap1'
}
export default contextJap1
