import styled from 'styled-components'
import { ligas } from "../ligas/contexts"
import { useContext, useEffect, useState } from "react"
import React from 'react';
import DarkContext from "../gerais/DarkContext";
    
export default function Topo({camp}){
    const {data}=useContext(DarkContext)
    const {imagens,paths}=ligas
    const lis=data?data.split('-'):['','']
    const [dia,hora]=lis
    return(
        <Tudo >
            <section>
                <p>{dia.replace('Começou!','')}</p>
                <p>{hora}</p>
            </section>
        </Tudo>
    )
}
const Tudo=styled.div`

position:absolute;top:7px;img{height:26px;margin-right:5px;}
section{
p{font-size:13px;}
display:flex;flex-direction:column;
height:28px;align-items:center;
justify-content:space-between;
}
`
