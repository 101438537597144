import styled from "styled-components"
import { useParams } from "react-router-dom"
import fundoPost from './fundoPost.png'
import { ligas } from "../ligas/contexts"
import Escudo from "../pag_2/Escudo"
import { getInfosCriarPost } from "../api"
import { useEffect, useState } from "react"
import  logoPreta from './logoPreta.png'
export default function CriarPrint(){
    const {liga,manvis,infovalor}=useParams()
    const {paths,nomes,orients,cores,contexts}=ligas
    const cor=cores[paths.indexOf(liga)].split(',')
    const orient=orients[paths.indexOf(liga)]
    const {listaNomes,listaTimes}=contexts[paths.indexOf(liga)]
    const mandante=manvis[0]+manvis[1]+manvis[2]
    const visitante=manvis[3]+manvis[4]+manvis[5]
    const [resposta,setResposta]=useState(false)
    function buscarApostas(){
        const promise=getInfosCriarPost(liga,manvis,infovalor)
        promise.then(res=>{
            setResposta(res.data)
        })
      }
      useEffect(buscarApostas,[])
      const {guru,analise,data}=resposta
      
      function formarFrase(texto){
        const grandeza=guru[0].grandeza
        let resp=''
        if(grandeza==7||grandeza==8){
            resp+='partidas em que '
        }else{
            resp+='partidas com '
        }
        resp+=texto
        if(grandeza==1){
            if(resp.includes('vitórias'))resp=resp.replace('vitórias','vitória')
            if(resp.includes('derrotas'))resp=resp.replace('derrotas','derrota')
            if(resp.includes('empates'))resp=resp.replace('empates','empate')
        }
    return resp
      }
    return (
        <Tudo>
            <Post>
                <ImagemFundo><img src={fundoPost}/></ImagemFundo>
                {resposta?<Resto>
                    <Logo><img src={logoPreta}/></Logo>
                    <Rodape><p>Para mais análises aproveite nossa plataforma gratuita: <strong>futbase.pro</strong></p></Rodape>
                    <Bandeira orient={orient}>
                            {orient?<ListraH cor={cor[1]} tamanho={110}/>:<ListraV cor={cor[1]} tamanho={50}/>}
                            {orient?<ListraH cor={cor[3]} tamanho={80}/>:<ListraV cor={cor[3]} tamanho={80}/>}
                            {orient?<ListraH cor={cor[5]} tamanho={50}/>:<ListraV cor={cor[5]} tamanho={110}/>}
                        </Bandeira>
                    <HolderTimes>
                        <Data>
                            <h1><small>{data[0]}</small></h1>
                            <h1>{data[1]}</h1>
                            </Data>
                        
                        <Time>
                            <Escudo camp={liga} time={mandante} noClick={true}/>
                            <h1>{listaNomes[listaTimes.indexOf(mandante)]}</h1>
                            <Quadrado>
                                <h2><strong>{guru[0].pos}º</strong> na liga</h2>
                                <h2>{guru[0].descricao}</h2>
                                {guru[0].grandeza==2||guru[0].grandeza==6?<h2>média: <strong>{guru[0].valor}</strong></h2>:<></>}
                            </Quadrado>
                            <Quadrado baixo={true}>
                                <h2>{formarFrase(analise[guru[0].estadia==0?0:1][0].texto)}</h2>
                                <HolderBarr>
                                    <Barrinha chance={analise[guru[0].estadia==0?0:1][0].chance} />
                                <h2><strong>{analise[guru[0].estadia==0?0:1][0].chance.toFixed(0)}</strong><small> %</small></h2>
                                </HolderBarr>
                            </Quadrado>
                        </Time>
                        <Time>
                            <Escudo camp={liga} time={visitante} noClick={true}/>
                            <h1>{listaNomes[listaTimes.indexOf(visitante)]}</h1>
                            <Quadrado>
                                <h2><strong>{guru[1].pos}º</strong> na liga</h2>
                                <h2>{guru[1].descricao}</h2>
                                {guru[0].grandeza==2||guru[0].grandeza==6?<h2>média: <strong>{guru[1].valor}</strong></h2>:<></>}
                            </Quadrado>
                            <Quadrado baixo={true}>
                                <h2>{formarFrase(analise[guru[0].estadia==0?0:1][1].texto)}</h2>
                                <HolderBarr>
                                    <Barrinha chance={analise[guru[0].estadia==0?0:1][1].chance}/>
                                    <h2><strong>{analise[guru[0].estadia==0?0:1][1].chance.toFixed(0)}</strong><small> %</small></h2>
                                </HolderBarr>
                            </Quadrado>
                        </Time>
                        
                    </HolderTimes>
                    <Aposta>
                        <h2>{analise[2].tex}</h2>
                        <section>
                        <p>odd</p>
                        <h2><strong>{analise[2].ode}</strong></h2>
                        </section>
                    </Aposta>
                </Resto>:<></>}
            </Post>
        </Tudo>
    )
}
/*
<BotaoDow id='botao' onClick={()=>{
                const post=document.getElementById(`post`)
                htmlToImage.toPng(post).then(
                    function(dataUrl){
                        const img=new Image()
                        img.src=dataUrl
                        document.body.appendChild(img)
                    }
                ).catch(err=>console.log(err))
            }}>download</BotaoDow>
            */
const Rodape=styled.div`
position:absolute;
font-size:15px;
strong{font-size:20px;font-weight:600;margin-left:5px;}
bottom:10px;
img{height:65px}
`
const Logo=styled.div`
position:absolute;
top:28px;
img{height:65px}
`
const ListraH=styled.div`
width:${props=>props.tamanho}px;
height:30px;
background-image: linear-gradient(to left,${props=>props.cor},transparent);
`
const ListraV=styled.div`
height:${props=>props.tamanho}px;
width:30px;
background-image: linear-gradient(to bottom,${props=>props.cor},transparent);
`
const Bandeira=styled.section`
display:flex;
flex-direction:${props=>props.orient?'column':'row'};
position:absolute;top:0;right:0;z-index:20;
align-items:${props=>props.orient?'flex-end;':'flex-start'};
`
const Data=styled.div`
position:absolute;top:105px;
flex-direction:column;
height:48px;
justify-content:space-between;
small{font-size:19px}
`
const HolderBarr=styled.div`
width:100%;justify-content:space-evenly;
`
//font-family: "Staatliches", sans-serif;
function Barrinha({chance}){
    return(
        <Bar >
            {chance>0?<Verde width={chance} />:<></>}
        </Bar>
    )
}
const Bar=styled.div`
margin-right:5px;
border-radius:7.5px;
height:15px;
width:80%;
background-color:#DD4A4A;
`
const Verde=styled.div`
border-radius:7.5px;
border-right:1px solid #969696;
top:0;
left:-0.5px;
box-sizing:border-box;
background-color:#4CD85E;
height:100%;
width:${props=>props.width}%
`

const Aposta=styled.div`position:relative;margin-top:10px;
justify-content:space-evenly;
border-radius:20px;
color:white;
section{width:50px;
    h2{max-width:100%;MARGIN-BOTTOM:2PX;}
display:flex;flex-direction:column;align-items:flex-end;
};
height:60px;width:200px;background-color:#4148E8;
h2{
    font-size:14px;max-width:60%;
    strong{font-size:24px;};
    
}
p{font-size:13px;margin:0 0 5px 0;color:#05d3fc}
`
const Quadrado=styled.div`
box-sizing:border-box;
flex-direction:column;justify-content:space-evenly;
color:white;
height:80px;width:100%;background-color:#343434;
margin:10px 0 0px 0;
border-top-right-radius:${props=>props.baixo?0:20}px;
border-top-left-radius:${props=>props.baixo?0:20}px;
border-bottom-right-radius:${props=>props.baixo?20:0}px;
border-bottom-left-radius:${props=>props.baixo?20:0}px;
padding:${props=>props.baixo?'0 10px 0 10px':'0 10px 0 10px'};
h2{
    font-size:14px;
    strong{font-weight:700;}
    small{font-size:12px;}
}
`
const Time=styled.div`position:relative;
img{height:60px;margin-bottom:10px}
flex-direction:column;
height:90%;
width:45%;margin-top:20px;
`
const HolderTimes=styled.div`
margin:70px 0 8px 0;
justify-content:space-evenly;
height:60%;width:100%;background-color:;
`
const Post=styled.div`
height:500px;width:500px;position:relative;
`
const Resto=styled.div`
flex-direction:column;
position:absolute;height:500px;width:500px;top:0;
h1{font-family: "Staatliches", sans-serif;font-weight:500;
font-size:22px;
    strong{font-size:40px;font-weight:300;}
}
`
const ImagemFundo=styled.div`
height:100%;img{height:100%}
`
const Tudo=styled.div`
justify-content:center;
position:fixed;
;z-index:6;
width:100vw;
height:100vh;
background-color:white;
max-width:700px;
top:0;
flex-direction:column;
background-color:grey;
`
