import styled from "styled-components"
import { useParams } from "react-router-dom"
import fundoPost from './fundoPost.png'
import { ligas } from "../ligas/contexts"
import Escudo from "../pag_2/Escudo"
import { getInfosCriarPost } from "../api"
import { useContext, useEffect, useState } from "react"
import  logoVerde from './logoVerde.png'
import DarkContext from "./DarkContext"
export default function ResultPrint(){
    const {   postResultado,setPostResultado
    }=useContext(DarkContext)
    const {liga}=useParams()
    const {paths,nomes,orients,cores,contexts}=ligas
    const cor=cores[paths.indexOf(liga)].split(',')
    const orient=orients[paths.indexOf(liga)]
    const {listaNomes,listaTimes}=contexts[paths.indexOf(liga)]
    const [resposta,setResposta]=useState(false)
    
    return (
        <Tudo>
            <Post>
                <ImagemFundo>
                    <img src={fundoPost}/>
                    
                    <Bandeira orient={orient}>
                            {orient?<ListraH cor={cor[1]} tamanho={110}/>:<ListraV cor={cor[1]} tamanho={50}/>}
                            {orient?<ListraH cor={cor[3]} tamanho={80}/>:<ListraV cor={cor[3]} tamanho={80}/>}
                            {orient?<ListraH cor={cor[5]} tamanho={50}/>:<ListraV cor={cor[5]} tamanho={110}/>}
                        </Bandeira>
                </ImagemFundo>
                <Resto>
                    <Logo><img src={logoVerde}/></Logo>
                    <Titulo>
                        <p>RESULTADOS</p>
                        <p><strong>{nomes[paths.indexOf(liga)]}</strong></p>
                    </Titulo>
                    <Quadro>
                    {postResultado.map(ap=>{
                        const {odd,chance,texto,camp,mandante,visitante,green,info,valor,nome,ev}=ap
                        return(
                            <Combo backcolor={(green==100?'59e07b':'f79b9b')}>
                                <Aposta backcolor={(green==100?'59e07b':'f79b9b')}>
                                    <h2>{texto}</h2>
                                    
                                </Aposta>
                                <main>
                                <Jogo>
                                    <Time>
                                    
                                        <Escudo camp={liga} time={mandante} noClick={true}/>
                                        <p>{listaNomes[listaTimes.indexOf(mandante)]}</p>
                                    </Time>
                                    <p><ion-icon name="close-outline"></ion-icon></p>
                                    <Time>
                                    
                                        <Escudo camp={liga} time={visitante} noClick={true}/>
                                        <p>{listaNomes[listaTimes.indexOf(visitante)]}</p>
                                    </Time>
                                    
                                </Jogo>
                                
                                </main>
                                <Ode backcolor={(green==100?'26b74a':'ce6f6f')}>{odd.toFixed(2)}</Ode>
                            </Combo>
                        )
                    })}
                    </Quadro>
                    
                        
                        
                </Resto>
            </Post>
        </Tudo>
    )
}
const Ode=styled.div`
color:white;
font-weight:600;
font-size:18px;
border-bottom-right-radius:10px;
border-top-left-radius:10px;
justify-content:center;
position:absolute;right:0;bottom:0;
width:50px;height:30px;
background-color:#${props=>props.backcolor}
`
const Quadro=styled.div`
flex-wrap:wrap;
justify-content:space-evenly;
padding:0 1.5% 0 1.5%;
`
const Titulo=styled.section`
width:265px;
display:flex;
align-items:flex-start;
flex-direction:column;
position:absolute;
font-size:45px;
color:var(--verde1);
strong{font-size:45px;font-weight:600;color:black}
top:20px;left:115px;
img{height:65px}
font-family: "Staatliches", sans-serif;
`
const Jogo=styled.div`
width:75%;justify-content:space-evenly;;
img{height:27px}
`
const Time=styled.div`
width:95px;background-color:;
flex-direction:column;height:50px;
justify-content:space-evenly;
p{font-size:11.5px;height:px;}
`
const Combo=styled.div`position:relative;
padding:5px 5px 5px 5px;box-sizing:border-box;
background-color:#${props=>props.backcolor};
flex-direction:column;
justify-content:space-between;
width:47%;height:100px;
border-radius:10px;
margin-bottom:10px;
main{display:flex;justify-content:flex-start;}
`
const Logo=styled.div`
position:absolute;z-index:67;
top:28px;left:28px;
img{height:65px}
`
const ListraH=styled.div`
border-top-right-radius:${props=>props.tamanho==110?15:0}px;
width:${props=>props.tamanho}px;
height:30px;
background-image: linear-gradient(to left,${props=>props.cor},transparent);
`
const ListraV=styled.div`
border-top-right-radius:${props=>props.tamanho==110?15:0}px;
height:${props=>props.tamanho}px;
width:30px;
background-image: linear-gradient(to bottom,${props=>props.cor},transparent);
`
const Bandeira=styled.section`
display:flex;
flex-direction:${props=>props.orient?'column':'row'};
position:absolute;top:1.5%;right:0;z-index:1;
align-items:${props=>props.orient?'flex-end;':'flex-start'};
`
//font-family: "Staatliches", sans-serif;

const Aposta=styled.div`position:relative;
justify-content:space-evenly;
border-radius:10px;
color:black;
height:44px;width:100%;
h2{
    font-size:13px;max-width:95%;
    line-height:15px;
}
`

const Post=styled.div`background-color:var(--verde1);
height:500px;width:500px;position:relative;
justify-content:center;z-index:1;
`
const Resto=styled.div`
padding-top:115px;flex-direction:column;
position:absolute;height:500px;width:500px;top:0;
h1{font-family: "Staatliches", sans-serif;font-weight:500;
font-size:22px;
    strong{font-size:40px;font-weight:300;}
}
`
const ImagemFundo=styled.div`position:relative;
height:100%;img{height:97%;border-radius:15px;};
`
const Tudo=styled.div`
justify-content:center;
position:fixed;
;z-index:6;
width:100vw;
height:100vh;
background-color:white;
max-width:700px;
top:0;
flex-direction:column;
background-color:grey;
`
