import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import DarkContext from "../gerais/DarkContext";
import SeletorTabela from "./SeletorTabela";
import Tabela from "./Tabela";
import Loader from "../pag_2/Loader";
import { useNavigate, useParams } from "react-router-dom";
import { getTabela } from "../api";
import { ligas } from "../ligas/contexts";
import SeletorTabela2 from "../gerais/old/SeletorTabela2";

export default function BuscarTabela(){
    const navigate=useNavigate()
    const {bandeira,a_tabela,
        estadia,metade,handicap
      }=useContext(DarkContext)
    const [tabela,setTabela]=useState([])
    const page=a_tabela
    useEffect(()=>{if(!page)navigate('/')},[])
    const paths=ligas.paths
    const context=ligas.contexts[paths.indexOf(bandeira)]
    function buscar(){
        if(!page)return
        setTabela(false)
        const promise=getTabela(bandeira,page,estadia,metade,handicap)
        promise.then(res=>{
            setTabela(res.data)
        })
    }
   useEffect(buscar,[estadia,metade,handicap,page])
    return(
        tabela?
        <Tudo>
            {page!=5?<SeletorTabela comMetade={page==1||page==2} />:<SeletorTabela2 />}
            {page?<Tabela grandeza={page} tabela={tabela} context={context}  />:<></>}
        </Tudo>:
        <Loader/>
    )
}
const Tudo=styled.div`
width:94%;
height:calc(100% - 0px);
max-width:400px;
background-color:white;
flex-direction:column
`