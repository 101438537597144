import styled from "styled-components";
import moeda from '../gerais/moedaFutbase.png'
export default function Cab({valor,semAberto}){
    
    return(
            <Tudo>
              <p>SALDO: <img src={moeda}/> <strong>{valor[0].toFixed(2)}</strong></p> 
              {semAberto?<></>:<p>EM ABERTO: <img src={moeda}/> <strong>{valor[1].toFixed(2)}</strong></p> }
            </Tudo>
    )
}

const Tudo=styled.div`
width:90%;
height:50px;
justify-content:space-evenly;
background-color:;
img{height:30px;}
justify-content:space-between;
p{font-size:13px;display:flex;align-items:center;strong{font-size:18px;}}font-weight:500;
`