import { useContext, useState } from "react";
import { useEffect } from "react";
import styled from "styled-components";
import DarkContext from "../gerais/DarkContext";
import { useNavigate, useParams } from "react-router-dom";
import { getApostas, getTime } from "../api";
import { _COMB, ligas } from "../ligas/contexts";
import Apostinha from "./Apostinha";
import moeda from '../gerais/moedaFutbase.png'
import Cab from "./Teclado";
export default function Simular({troca}){
    const navigate=useNavigate()
    const {combNova,
        swit,setSwit}=useContext(DarkContext)
        const [tex,setTex]=useState('')
        const [dispo,setDispo]=useState(false)
        const [retorno,setRetorno]=useState(0)
        function salvarCombinacao(){
            if(parseInt(tex.length>3?tex[0]+tex[1]+tex[2]:tex)>parseInt(dispo))return
            const finalComb={aps:combNova.map(apo=>apo.nome),valor:parseInt(tex.length>3?tex[0]+tex[1]+tex[2]:tex)}
            const listaCombs=JSON.parse(localStorage.getItem(_COMB))||[]
            const novaLista=[finalComb,...listaCombs]
            localStorage.setItem(_COMB, JSON.stringify(novaLista))
            navigate('/multiplas')
        }
        function calcularoddComb(){
            let x=1
            for(let k=0;k<combNova.length;k++){
                x=x*combNova[k].odd
            }
            setOddComb(x==1?0:x.toFixed(2))
        }
        const [oddComb,setOddComb]=useState(0)
        useEffect(()=>{
            if(tex){setRetorno((parseInt(tex)*parseFloat(oddComb)).toFixed(2))}else{setRetorno(0)}
            
        },[tex])
        useEffect(calcularoddComb,[])
        useEffect(()=>{
            const input=document.getElementById(`searchField2`)
            input.focus()             
        },[])
       
         
    function buscarCombs(){
        const promise=getApostas(2)
        promise.then(res=>{
            let disponivel=100

          let resultado=res.data
          const myCombs=JSON.parse(localStorage.getItem(_COMB))||[]
          const combsEmUmaLista=[]
          for(let comb of myCombs){for(let ap of comb.aps){combsEmUmaLista.push(ap)}}
          const final=myCombs.map(comb=>{
            const {aps,valor}=comb
            const apostas=aps.map(apNome=>darApostaApartirDoNome(resultado,apNome))
            let gree=true
            let x=1
            for(let k=0;k<apostas.length;k++){
                x=x*apostas[k].odd
                if(apostas[k].green===0)gree=false
                if(apostas[k].green==undefined && gree!==false)gree=null
            }
            const retorno=(valor*x).toFixed(2)
            disponivel-=valor
            if(gree===true)disponivel+=parseFloat(retorno)
            return(
            {   gree,retorno,
                odd:x,
                valor:comb.valor,
                apostas
            }
          )})
          setDispo([disponivel])
        })
      }
    function darApostaApartirDoNome(lista,nome){
        for(let ap of lista){
            if(ap.nome==nome)return ap
        }
    }
    useEffect(buscarCombs,[])
    return(
        <Tudo>
            {dispo?<Cab valor={dispo} semAberto={true}/>:<></>}
                    <Bloco height={100+combNova.length*82}>
                        <section>
                            <aside>
                            <img src={moeda}/>
                            <input id="searchField2" type='number' value={tex.length>3?tex[0]+tex[1]+tex[2]:tex} onChange={e=>{setTex(e.target.value)}}  placeholder='valor'></input>
                            </aside>
                            
                            {!retorno||parseInt(tex)>parseInt(dispo)?<FakeButton/>:<OkButton color={'002359'} onClick={()=>salvarCombinacao()}>SIMULAR</OkButton>}
                            {combNova.length==0?<></>:<p>{oddComb}</p>}
                        </section>
                        {parseInt(tex)>parseInt(dispo)?<Money><p>SALDO INSUFICIENTE</p></Money>
                        :<Money>
                            <p>RETORNO:</p>
                            <img src={moeda}/>
                            <h6>{retorno}</h6>
                        </Money>}
                        {combNova.map(apo=>
                            <Apostinha acaoComb={()=>{}} combinando={false}
                            onBloco={true} apo={apo}/>)}
                        
                        
                    </Bloco>
        </Tudo>
    )
}

const Money=styled.div`
height:50px;width:90%;
img{height:30px;}padding:0 7px 0 0;box-sizing:border-box;
justify-content:flex-end;
h6{font-size:18px;}
font-weight:500;
`
const Bloco=styled.div`max-width:400px;
margin:${props=>props.onProntas?'0px 0 20px 12px':'10px 0 0 0'};
width:94%;
display:flex;flex-direction:column;align-items:center;
justify-content:space-evenly;
position:relative;min-height:${props=>props.height}px;
background-color:#${props=>props.color||'7cb0ff'};
color:black;
border-radius:15px;
display:flex;
align-items:center;
justify-content:space-evenly;
aside{
    display:flex;width:60px;
}
section{
    img{height:30px;}align-items:center;
    width:94%;display:flex;justify-content:space-between;
    box-sizing:boxder-box;padding:0 10px 0 10px;
        p{display:flex;width:60px;
            justify-content:center;
            font-size:18px;font-weight:600;
        height:30px;align-items:center;
        box-sizing:border-box
        }
        h6{height:30px;
            display:flex;align-items:center;justify-content:center;
        }
        h2{
            font-size:20px;width:45px;
            small{font-size:11px}
            margin-bottom:12px;
        }
        h4{background-color:#001c47;color:white;
            position:absolute;bottom:0;left:0;
            height:18px;padding-left:10px;
            justify-content:center;
            display:flex;align-items:center;
            font-size:14px;width:45px;
        border-bottom-left-radius:16px;
        border-top-right-radius:16px;
        
        }
}
input{width:60px;font-size:15px;font-weight:400;
    background-color:#bcd3f4;height:30px;box-sizing:border-box;
border:0;color:;border-radius:10px;padding-left:7px;
}
`

const Tudo=styled.div`
background-color:white;
width:100%;flex-direction:column;
height:calc(100% - 0px);
flex-direction:column;
justify-content:;
`
const OkButton=styled.div`
cursor:pointer;background-color:#${props=>props.color};display:flex;
justify-content:center;align-items:center;color:white;
font-size:12PX;height:30px;border-radius:15px;
width:80px;
`
const NoButton=styled.div`
cursor:pointer;;display:flex;
justify-content:center;align-items:center;color:white;
font-size:12PX;height:30px;border-radius:15px;
width:30px;margin-right:${props=>props.noMargin?0:20}px;
`
const FakeButton=styled.div`
width:30px;margin-left:20px;
`