import DarkContext from "../gerais/DarkContext"

import styled from "styled-components"
import { useContext } from "react"
import { ligas } from "../ligas/contexts"

export default function Bandeirada({fechar}){
    const {paths,imagens,nomes}=ligas
    return(
        <Tudo onClick={fechar}>
            <Quadro>
                {paths.map((path,index)=>{
                return(
                    <Campeonato fechar={fechar} path={path} imagem={imagens[index]} nome={nomes[index]} />
                )
            })}
            <CampFalso/>
            </Quadro>
            
        </Tudo>
    )
}
function Campeonato({path,imagem,nome,fechar}){
    const {
        setBandeira,bandeira
    }=useContext(DarkContext)
    return(
        <Camp selected={path==bandeira}
         onClick={()=>{setBandeira(path);fechar()}} >
            <img src={imagem}/>
            <h1>{nome}</h1>
        </Camp>
    )
}
const Quadro=styled.section`
padding-top:30px;
box-sizing:border-box;
display:flex;align-items:flex-start;
flex-direction:row;justify-content:space-evenly;
border-radius:30px;flex-wrap:wrap;
position:absolute;top:15%;
background-color:white;height:70%;width:320px;
`
const Camp=styled.div`
background-color:${props=>props.selected?'#cccccc':'transparent'};
font-size:12px;
justify-content:space-evenly;
color:#545454;
height:80px;width:28%;flex-direction:column;
img{height:36px}
h1{
    font-size:14px;
};
cursor:pointer;
border-radius:10px;

`
const CampFalso=styled.div`
background-color:transparent;
height:80px;width:28%;
`
const Tudo=styled.div`
position:fixed;
;z-index:21;
width:100vw;
height:100vh;
background-color:rgb(0,0,0,0.5);
max-width:700px;
top:0;
flex-direction:column;
`
