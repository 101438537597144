import styled from "styled-components"
import { ligas } from "../ligas/contexts"
import { useNavigate } from "react-router-dom"

export default function CriarEscLiga(){
    const {pathsApostaveis,imagens,nomes}=ligas
    const navigate=useNavigate()
    return (
        <Tudo>
            <Lista>
                {pathsApostaveis.map((pat,index)=><Camp onClick={()=>navigate(`/criarposts/${pat}`)}>
                    <img src={imagens[index]}/>
                    <h1>{nomes[index]}</h1>
                </Camp>)}
            </Lista>
        </Tudo>
    )
}
const Tudo=styled.div`justify-content:center;
position:fixed;width:100vw;height:100vh;background-color:grey;
`
const Lista=styled.div`
height:80%;width:400px;flex-direction:column;
`
const Camp=styled.div`border-radius:10px;cursor:pointer;
img{height:80%;margin-right:5px}h1{font-size:15px;}
width:100%;height:50px;background-color:white;margin-bottom:10px;
`