import { useNavigate } from "react-router";
import { ligas } from "../ligas/contexts";
import { useContext } from "react";
import DarkContext from "../gerais/DarkContext";
import Escudo from "../pag_2/Escudo";
import styled from "styled-components";
import ReactLoading from 'react-loading';
export function JoguinhoFut({texto,path,mandante,visitante,setarKeys,id,doCriar}){
    const {paths,imagens}=ligas
    const navigate=useNavigate()
    const {setEscoAnalise,setKeys,keys,seta_confrontos,
        setSwit3}=useContext(DarkContext)
    return (
        <Faltam nulo={texto==''} id={id}>
            <Jogo 
             onClick={()=>{
                if(doCriar){
                    if(doCriar==1){
                      navigate(`/criarposts/${path}/${mandante+visitante}`)
                    }else{
                      navigate(`/postresultado/${path}/${mandante+visitante}`)
                    }
                    
                }else{
                    setarKeys()
                    setKeys({...keys,confronto:0})
                      seta_confrontos(`${path}-${mandante}-${visitante}`)
                      navigate(`/confrontos`)
                    setEscoAnalise(true)
                    setSwit3(true)
                }
             }} >
              <Bandeira><img src={imagens[paths.indexOf(path)]}/></Bandeira>
              <Layer degrade={texto==''?'linear-gradient(to left,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,rgba(255, 255, 255, 0.4))':'linear-gradient(to left,white,white,white,white,white,rgba(255, 255, 255, 0.4))'}/>
              <HolderEscudos>
                  <Escudo camp={path} time={mandante} noClick={true}/>
                  <p><ion-icon name="close-outline"></ion-icon></p>
                  <Escudo camp={path} time={visitante} noClick={true}/>
              </HolderEscudos>
            </Jogo>
            <span>
              {texto[0]=='C'?<ReactLoading type={'cylon'} color={'white'} height={22} width={32} />:<></>}
            </span>
            <h1>{texto[0]=='C'?texto.split('!')[0]+'!':texto}</h1>
        </Faltam>
    )
}
const Bandeira=styled.div`
overflow: hidden;
border-top-left-radius:15px;
border-bottom-left-radius:15px;
height:100%;width:100px;
position:absolute;left:-0px;top:0px;
img{height:125%;
left:-11px;
position: absolute;
    clip: rect(0px, 100px, 100px, 0px);
}
`
const Faltam=styled.section`
min-height:${props=>props.nulo?72:91}px;
background-color:#003817;
position:relative;
margin:0px 0 10px 12px;
box-sizing:border-box;
padding-top:3px;
max-width:400px;
border-radius:15px;
width:94%;
display:flex;
justify-content:center;
span{
  position:absolute;bottom:-2.2px;align-items:center;left:30px;
  display:flex;justify-content:space-evenly;
};
h1{display:flex;justify-content:center;width:100%;
  color:white;position:absolute;bottom:2.4px;
  font-size:13px;
}
`
const Layer=styled.div`
position:absolute;right:0;
width:100%;z-index:10;
height:100%;;
border-radius:15px;
background-image:${props=>props.degrade};

`

const Jogo=styled.div`
ion-icon{font-size:16px}
width:calc(100% - 6px);
height:72px;
cursor:pointer;
position:absolute;
justify-content:space-evenly;

border-radius:15px;
h1{font-weight:700;font-size:18px;}
p{
  width:65%;font-size:25px;font-weight:500;height:100%;
display:flex;align-items:center;justify-content:center;
small{font-size:18px;margin:0 5px 0 5px;}
}
`
const HolderEscudos=styled.div`
width:70%;
z-index:11;
width:140px;
height:100%;
img{
    height:60%;width:auto
};
`