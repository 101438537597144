
import { useContext, useEffect, useState } from "react"
import styled from "styled-components"
import React from 'react';
import DarkContext from "../gerais/DarkContext";
import Loader from "../pag_2/Loader";
import { getApostas } from "../api";
import Apostinha from "./Apostinha";
import { useNavigate } from "react-router-dom";
import { AiOutlineClose, AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import { _COMB, _FAV, ligas } from "../ligas/contexts";
export default function Favoritas(){
    const {setPagina,keys,setKeys,setCombNova,setPageFavoritas,
        }=useContext(DarkContext)
        const navigate=useNavigate()
    const [lista,setLista]=useState(false)
    const [combinando,setCombinando]=useState(false)
    const [comb,setComb]=useState([])
    const [oddComb,setOddComb]=useState(0)
    const [evComb,setEvComb]=useState(0)
    const [listaRaw,setListaRaw]=useState(false)
    const [aviso,setAviso]=useState('')
    function buscarBet(){
      setLista(false)
      const promise=getApostas(2)
      promise.then(res=>{
        const {data}=res
        const listaEstrelas=JSON.parse(localStorage.getItem(_FAV))||[]
        const resultado=data.filter(ap=>listaEstrelas.includes(ap.nome))
        setLista(resultado)
        setListaRaw(resultado)
      })
    }
    
    function darApostaApartirDoNome(lista,nome){
        for(let ap of lista){
            if(ap.nome==nome)return ap
        }
    }
    function calcularoddComb(){
        let ch=1
        let gree=true
        let x=1
        for(let k=0;k<comb.length;k++){
            ch=ch*comb[k].chance
            ch=ch/100
            x=x*comb[k].odd
            if(comb[k].green===0)gree=false
            if(comb[k].green==undefined && gree!==false)gree=null
        }
        ch=ch*100
        const ev= x*ch-(100-ch)
        setOddComb(x==1?0:x)
        setEvComb(ev)
    }
    function colocarNoComb(aposta){
        if(aposta.green!==undefined){
            setAviso(aposta.green===null?'Esse jogo já começou':'Esse jogo já aconteceu')
            setTimeout(() => {
                setAviso('')
            }, 1000)
            return
        }
        if(comb.length==4){
            setAviso('Você pode adicionar até 4 apostas')
            setTimeout(() => {
                setAviso('')
            }, 1000)
            return
        }
        for(let k=0;k<comb.length;k++){
            const {camp,mandante,visitante}=aposta
            const {camp:campp,mandante:mandantee,visitante:visitantee}=comb[k]
            if(camp==campp&&mandante==mandantee&&visitante==visitantee){
                setAviso('Não é permitido 2 apostas do mesmo jogo')
                setTimeout(() => {
                    setAviso('')
                }, 1000)
                return
            }
        }
        setComb([...comb,aposta])
        const novaLista=[]
        for(let k=0;k<listaRaw.length;k++){
            if(aposta.nome!=listaRaw[k].nome)novaLista.push(listaRaw[k])
        }
        setListaRaw(novaLista)
    }
    function tirarDoComb(aposta){
        const novoComb=[]
        for(let k=0;k<comb.length;k++){
            if(aposta.nome!=comb[k].nome)novoComb.push(comb[k])
        }
        setComb(novoComb)
        const listaNova=[aposta,...listaRaw]
        setListaRaw(listaNova)
    }
    
    function salvarCombinacao(){
        if(comb.length==0)return
        setCombNova(comb)
        navigate('/simular')
    }
    
    function acaoSumir(index){
        const novaLista=[...lista]
        novaLista.splice(index,1)
        setLista(novaLista)
        setListaRaw(novaLista)
        setKeys({...keys,fav:[1,null]})
    }
    useEffect(()=>{
            const item=keys.fav
            if(item[0]==1&&lista&&item[1]){
                document.getElementById(`fav${item[1]}`).scrollIntoView()
            }
    },[lista])
    
    useEffect(calcularoddComb,[comb.length])
    useEffect(buscarBet,[])
    
    return(
         <Tudo>
            <Topo height={70+(comb.length-1)*80+(comb.length>0?70:0)}>
                {combinando?
                    <Bloco height={50+comb.length*82}>
                        
                        {comb?comb.map(apo=>
                            <Apostinha acaoComb={()=>tirarDoComb(apo)} combinando={combinando}
                            onBloco={true} apo={apo}/>
                        ):<></>}
                        {aviso?
                        <section>
                            <h6>{aviso}</h6>
                        </section>
                        :<section>
                            {true?<></>:<h4>{evComb.toFixed(0)}</h4>}
                            <NoButton/>
                            {comb.length==0?<h6>Clique nas apostas para adicioná-las</h6>:<></>}
                            {comb.length==0?<FakeButton/>:<OkButton color={'002359'} onClick={()=>salvarCombinacao()}>PRONTA</OkButton>}
                            {comb.length==0?<></>:<p>{oddComb.toFixed(2)}</p>}
                        </section>}
                    </Bloco>:lista.length==0||lista===false?<></>:
                    <Combinar onClick={()=>setCombinando(true)}><ion-icon name="list-circle-outline"></ion-icon>SIMULAR APOSTA</Combinar>
                }
            </Topo>
            {
                lista?lista.length==0?<ViewNada>
                    <div>
                        <h6>Você não tem apostas favoritas</h6>
                        <h6>Clique na estrela no canto direito das apostas para favoritá-las</h6>
                        <ion-icon name="star-outline"></ion-icon>
                    </div>
                </ViewNada>:<Quadro>
                    {(combinando?listaRaw:lista).map((apo,index)=>
                        <Apostinha praSumir={true} acaoSumir={()=>acaoSumir(index)} acaoComb={()=>colocarNoComb(apo)} combinando={combinando} setarKeys={()=>setKeys({...keys,fav:[1,index]})} id={`fav${index}`} apo={apo}/>
                    )}
                    <MargemBaixo/>
                </Quadro>:<Loader />}
        </Tudo>
    )
}
const MargemBaixo=styled.div`
min-height:40px;width:50px;background-color:;
`
const ViewNada=styled.div`
height:calc(100% - 70px);
width:100%;
justify-content:center;
div{width:60%;height:200px;justify-content:space-between;
    flex-direction:column;
}
font-size:18px;line-height:25px;
ion-icon{font-size:60px;}
color:#545454;
`
const BtnNumero=({name,handlePress})=>{
    return(
        <BotaoNumero  onClick={handlePress}>
            <ion-icon name={name}></ion-icon>
        </BotaoNumero>
    )
}
const Escolha=styled.div`cursor:pointer;
background-color:${props=>props.shown?'#002359':'transparent'};
font-size:12px;
justify-content:center;
color:white;
border:0;
width:49%;
height:50px;
h1{
    margin-left: auto;margin-right: auto;font-size:12px;
    small{font-size:12px;}
}
margin:10px 0 0 0;
border-radius:5px;
:active{
    box-shadow: inset 3px 3px 5px rgba(0, 0, 0, 0.2);
    }
`
const BotaoNumero=styled.button`background-color:transparent;
font-size:28px;color:#545454;
justify-content:center;
height:35px;width:35px;
border-radius:50%
`
const HolderBtnNum=styled.div`
height:35px;width:35px;
`
const Topo=styled.div`
max-width:400px;
height:${props=>props.height}px;width:94%;
flex-direction:column;
justify-content:space-evenly;
`

const Quadro=styled.section`position:relative;
margin-top:15px;
max-width:400px;
height:calc(100% - 70px);
width:100%;
display:flex;
flex-direction:column;
align-items:flex-start;
overflow:hidden;
overflow-y:scroll;
::-webkit-scrollbar {
    width:12px
  }
  ::-webkit-scrollbar-track {
    border-top-right-radius:5px;
    border-bottom-right-radius:5px;
  }
  ::-webkit-scrollbar-thumb {
    background: #e5e5e5; 
    border-radius:5px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #cecece; 
  }`

const HolderLoader=styled.div`height:calc(100% - 180px)`

const Tudo=styled.div`
background-color:white;
width:100%;flex-direction:column;
height:calc(100% - 0px);

article{display:flex;width:94%;max-width:400px;justify-content:space-between;}
`
const Bloco=styled.div`max-width:400px;
margin:${props=>props.onProntas?'0px 0 20px 12px':'10px 0 0 0'};
width:${props=>props.onProntas?94:100}%;
display:flex;flex-direction:column;align-items:center;
justify-content:space-evenly;
position:relative;min-height:${props=>props.height}px;
background-color:#${props=>props.color||'7cb0ff'};
color:black;
border-radius:15px;
display:flex;
align-items:center;
justify-content:space-evenly;
section{
    width:94%;display:flex;justify-content:space-between;
    box-sizing:boxder-box;padding:0 10px 0 10px;
        p{display:flex;width:45px;
            font-size:18px;font-weight:600;
        height:30px;align-items:center;
        box-sizing:border-box
        }
        h6{height:30px;
            display:flex;align-items:center;justify-content:center;
        }
        h2{
            font-size:20px;width:45px;
            small{font-size:11px}
            margin-bottom:12px;
        }
        h4{background-color:#001c47;color:white;
            position:absolute;bottom:0;left:0;
            height:18px;padding-left:10px;
            justify-content:center;
            display:flex;align-items:center;
            font-size:14px;width:45px;
        border-bottom-left-radius:16px;
        border-top-right-radius:16px;
        
        }
}

`
const OkButton=styled.div`
cursor:pointer;background-color:#${props=>props.color};display:flex;
justify-content:center;align-items:center;color:white;
font-size:12PX;height:30px;border-radius:15px;
width:80px;
`
const NoButton=styled.div`
cursor:pointer;;display:flex;
justify-content:center;align-items:center;color:white;
font-size:12PX;height:30px;border-radius:15px;
width:30px;margin-right:${props=>props.noMargin?0:20}px;
`
const FakeButton=styled.div`
width:30px;margin-left:20px;
`
const Combinar=styled.div`
cursor:pointer;margin:10px 0 0 0;
font-size:20px;font-weight:500;
background-color:#7cb0ff;
cursor:pointer;color:black;
    font-size:12px;display:flex;align-items:center;
    justify-content:center;
    border:0;min-width:100%;height:50px;
h1{margin-left: auto;margin-right: auto;font-size:12px;
    small{font-size:12px;}
};
ion-icon{
font-size:30px;margin-right:10px;
}
border-radius:5px;
:active{
    box-shadow: inset 3px 3px 5px rgba(0, 0, 0, 0.2);
    }
`
const SalvarBet=styled.div`
position:absolute;bottom:0;height:42px;
cursor:pointer;background-color:#008037;display:flex;
justify-content:space-evenly;;align-items:center;color:white;
font-size:12PX;
border-top-left-radius:15px;
border-top-right-radius:15px;
width:58%;
h1{width:18%;font-size:18px;
    height:40px;display:flex;align-items:center;justify-content:center;
    border:0;background-color:;
}
main{width:22%;font-size:22px;
    height:40px;display:flex;align-items:center;justify-content:space-evenly;
    border:0;background-color:;
}
p{width:30%;
    height:40px;display:flex;align-items:center;justify-content:center;
    border:0;background-color:;
}
`