
import { useContext, useEffect, useState } from "react"
import styled from "styled-components"
import React from 'react';
import DarkContext from "../gerais/DarkContext";
import { useNavigate } from "react-router-dom";
import { ligas } from "../ligas/contexts";
import Escudo from "./Escudo";

export default function Procurar({onMenu}){
    const {setSwit,setBandeira,pesqSelec,setPesqSelec,setSwit2,
     setPrimeiraVez,setProcurando,setMenuExpandido,menuExpandido,seta_tabelas,seta_times   }=useContext(DarkContext)
    const [lista,setLista]=useState(false)
    const [text,setText]=useState('')
    const navigate=useNavigate()
    const {contexts,segundoNome,nomes,imagens,paths}=ligas
    function buscar(){
        setLista(false)
        if(text.length==0){
            
            const list=JSON.parse(localStorage.getItem("procurar"))||[]
            const newList=[]
            for(let item of list){
                if(paths.includes(item.path))newList.push(item)
            }
            localStorage.setItem("procurar", JSON.stringify(newList))
            return setLista(newList.reverse())
        }
        
        const resp=[]
        const texto=text[0].toUpperCase() + text.substring(1);
        for(let cont of contexts){
            const {listaNomes,listaTimes,path}=cont
            for(let nome of listaNomes){
                const lis=nome.split(' ')
                let igual=false
                for(let palavra of lis){if(palavra[0]==texto[0])igual=true}
                if(igual==true&&nome.includes(texto)){
                    const index=listaNomes.indexOf(nome)
                    resp.push({
                        nome,
                        sigla:listaTimes[index],
                        path
                    })
                }
            }
        }
        if(resp.length<2){
            for(let nome of segundoNome){
                const lis=nome.split(' ')
                let igual=false
                for(let palavra of lis){if(palavra[0]==texto[0])igual=true}
                if(igual==true&&nome.includes(texto)){
                    const index=segundoNome.indexOf(nome)
                    resp.push({
                        nome,
                        imagem:imagens[index],
                        path:paths[index]
                    })
                }
            }
        }
        /*if(resp.length<2){
            for(let nome of nomes){
                const lis=nome.split(' ')
                let igual=false
                for(let palavra of lis){if(palavra[0]==texto[0])igual=true}
                if(igual==true&&nome.includes(texto)){
                    const index=nomes.indexOf(nome)
                    resp.push({
                        nome,
                        imagem:imagens[index],
                        path:paths[index]
                    })
                }
            }
        }*/
        setLista(resp)
    }
    function salv(item){
        const list=JSON.parse(localStorage.getItem("procurar"))||[]
        
        let repetiu=false
        for(let ja of list)if(ja.nome==item.nome)repetiu=true
        if(!repetiu){
            const novaLista=[...list,item]
            const listaFinal=novaLista.length>10?novaLista.slice(-10):novaLista
            localStorage.setItem("procurar", JSON.stringify(listaFinal))
        }
    }
    function basicas(item,path){
        setPrimeiraVez(false)
        setProcurando(false)
        salv(item)
        setBandeira(path)
        setText('')
    }
    useEffect(buscar,[text])
    useEffect(()=>{
        const input=document.getElementById(`searchField`)
        if(!onMenu)input.focus()             
    },[])
    function determinarHeight(){
        if(!lista)return '60px'
        if(lista.length>6||pesqSelec)return '350px'
        return `${60+50*lista.length}px`
    }
    return(
        onMenu?<Tudo onMenu={onMenu} expande={onMenu&&menuExpandido}>
            <main>
            <input id="searchField" value={text} onClick={()=>setMenuExpandido(true)} onChange={e=>{setText(e.target.value);setPesqSelec(false)}}  placeholder='O que deseja encontrar?'></input>
            </main>
            <Quadro>
                {lista&&menuExpandido?
                    lista.map((item,index)=>{
                        const {nome,path,sigla,imagem}=item
                        return(imagem?
                            <HolderItem>
                            <Item onClick={()=>{setPesqSelec(pesqSelec==index?false:index)}}>
                                <HolderImg><img src={imagem}/></HolderImg>
                                <h1>{nome}</h1>
                                <ion-icon name={`chevron-${pesqSelec==index?'up':'down'}-outline`}></ion-icon>
                            </Item>
                            
                            <Choice handleClick={()=>{basicas(item,path);setSwit2(false);navigate(`/classificacao`)}} palavra='Partidas' icone='reorder-four-outline' show={pesqSelec==index} />
                            <Choice handleClick={()=>{basicas(item,path);setSwit2(true);navigate(`/classificacao`)}} palavra='Tabela' icone='medal-outline' show={pesqSelec==index} />
                            <Choice handleClick={()=>{basicas(item,path);navigate(`/tabelas`);seta_tabelas(1)}} palavra='Placar' icone='calendar-clear-outline' show={pesqSelec==index} />
                            <Choice handleClick={()=>{basicas(item,path);navigate(`/tabelas`);seta_tabelas(2)}} palavra='Gols' icone='football-outline' show={pesqSelec==index} />
                            <Choice handleClick={()=>{basicas(item,path);navigate(`/tabelas`);seta_tabelas(6)}} palavra='Escanteios' icone='golf-outline' show={pesqSelec==index} />
                            
                            </HolderItem>
                            :
                            <Item onClick={()=>{basicas(item,path);setSwit(false);navigate(`/times`);seta_times(`${sigla}`)}}>
                                <HolderImg><Escudo camp={path} time={sigla}/></HolderImg>
                                <h1>{nome}</h1>
                            </Item>
                        )
                    })
                :<></>}
            </Quadro>
        </Tudo>:
        <Layer>
         <Tudo height={determinarHeight()}>
            <main>
            <input id="searchField" value={text} onChange={e=>{setText(e.target.value);setPesqSelec(-1)}}  placeholder='O que deseja encontrar?'></input>
            </main>
            <Quadro>
                {lista?
                    lista.map((item,index)=>{
                        const {nome,path,sigla,imagem}=item
                        return(imagem?
                            <HolderItem>
                            <Item onClick={()=>{setPesqSelec(pesqSelec==index?-1:index)}}>
                                <HolderImg><img src={imagem}/></HolderImg>
                                <h1>{nome}</h1>
                                <ion-icon name={`chevron-${pesqSelec==index?'up':'down'}-outline`}></ion-icon>
                            </Item>
                            
                            <Choice handleClick={()=>{basicas(item,path);setSwit2(false);navigate(`/classificacao`)}} palavra='Partidas' icone='reorder-four-outline' show={pesqSelec==index} />
                            <Choice handleClick={()=>{basicas(item,path);setSwit2(true);navigate(`/classificacao`)}} palavra='Tabela' icone='medal-outline' show={pesqSelec==index} />
                            <Choice handleClick={()=>{basicas(item,path);navigate(`/tabelas`);seta_tabelas(1)}} palavra='Placar' icone='calendar-clear-outline' show={pesqSelec==index} />
                            <Choice handleClick={()=>{basicas(item,path);navigate(`/tabelas`);seta_tabelas(2)}} palavra='Gols' icone='football-outline' show={pesqSelec==index} />
                            <Choice handleClick={()=>{basicas(item,path);navigate(`/tabelas`);seta_tabelas(6)}} palavra='Escanteios' icone='golf-outline' show={pesqSelec==index} />
                            
                            </HolderItem>
                            :
                            <Item onClick={()=>{basicas(item,path);setSwit(false);navigate(`/times`);seta_times(`${sigla}`)}}>
                                <HolderImg><Escudo camp={path} time={sigla}/></HolderImg>
                                <h1>{nome}</h1>
                            </Item>
                        )
                    })
                :<></>}
            </Quadro>
        </Tudo>
        </Layer>
    )
}
function Choice({icone,palavra,show,handleClick}){
    return (show?
        <Cho onClick={handleClick}><ion-icon name={icone}/><h1>{palavra}</h1></Cho>:<></>
    )
}
const Layer=styled.div`
position:fixed;
;z-index:13;
width:100vw;
height:calc(100vh - 64px);
background-color:rgb(0,0,0,0.8);
max-width:400px;
top:64px;
flex-direction:column;
`
const Cho=styled.div`cursor:pointer;
width:calc(100% - 10px);min-height:50px;
img{cursor:pointer;height:40px;width:auto;}
margin-left:10px;box-sizing:border-box;
ion-icon{color:#545454; ;margin:0 10px 0 0px}
background-color:#E8E8E8;
padding-left:10px;
h1{margin:0 0px 0 5px;font-size:18px;font-weight:400}
`
const HolderItem=styled.div`background-color:;
width:100%;flex-direction:column;
`
const HolderImg=styled.div`
min-height:45px;
min-width:45px;
justify-content:center;
`
const Item=styled.div`cursor:pointer;
width:100%;max-height:50px;
img{cursor:pointer;height:40px;width:auto;}
h1{margin:0 0px 0 5px;font-size:18px;font-weight:400}
ion-icon{color:#545454; margin:0 0 0 10px;}
`
const Quadro=styled.div`max-width:400px;
height:calc(100% - 60px);
width:96%;
flex-direction:column;
overflow:hidden;
overflow-y:scroll;
::-webkit-scrollbar {
    width:12px
  }
  ::-webkit-scrollbar-track {
    border-top-right-radius:5px;
    border-bottom-right-radius:5px;
  }
  ::-webkit-scrollbar-thumb {
    background: #e5e5e5; 
    border-radius:5px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #cecece; 
  }
`
const Tudo=styled.div`
max-width:385px;

height:${props=>props.height};
width:97%;
z-index:21;
position:fixed;;
position:absolute;${props=>props.onMenu?(props=>props.expande?'top:70px;right:3%;width:94%;max-width:370px;border-top-right-radius:20px;':'top:460px;right:3%;width:94%;max-width:370px;border-top-right-radius:20px;'):'right:0;'};
background-color:white;
flex-direction:column;
main{background-color:;
    width:100%;display:flex;align-items:center;
    justify-content:space-evenly;
    margin:10px 0 10px 0;
}
input{
    font-size:14px;padding-left:10px;box-sizing:border-box;
    height:40px;width:calc(100% - 15px);max-width:400px;
    border-radius:10px;
    border:1px solid green;
}
border-top-left-radius:20px;
border-bottom-left-radius:20px;
border-bottom-right-radius:20px;

@media(min-width:400px){
    right:${props=>props.onMenu?'calc(50% - 185px)':'calc(50% - 200px)'}
}   
`