import { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import DarkContext from '../gerais/DarkContext'
import Escudo from '../pag_2/Escudo'

function sigla(str){
    const list=str.split(' ')
    let resp=''
    for(let w=0;w<list.length;w++){
        if(w==1&&list.length==3)resp+=' '
        resp+=list[w][0]
    }
    
    return resp
}
const campos = [
    [
        [sigla('Vitórias'),sigla('Empates'),sigla('Derrotas'),],
        ['%','%','%',],
        ['Vitórias','Empates','Derrotas',]
    ],[
        [sigla('Gols Marcados'),sigla('Gols'),sigla('Gols Sofridos')],
        ['','',''],
        ['Média gols marcados','Média gols','Média gols sofridos']
    ],[
        ['1º GM','1º G','1º GS',],
        ["'","'","'",],
        ['Média primeiro gol marcado','Média primeiro gol','Média primeiro gol sofrido',]
    ],[
        ['Ult GM','Ult G','Ult GS'],
        ["'","'","'"],
        ['Média último gol marcado','Média último gol','Média último gol sofrido']
    ],[
        [sigla('Gol Marcado'),sigla('Sem Gols'),sigla('Gol Sofrido')],
        ['%','%','%'],
        ['Vezes em que marcou o gol','Vezes em que não houve mais gols','Vezes em que sofreu o gol']
    ],[
        [sigla('Escanteios Favor'),sigla('Escanteios'),sigla('Escanteios Contra')],
        ['','',''],
        ['Escanteios a favor','Escanteios','Escanteios contrários']
    ],[
        [sigla('Marca Primeiro'),sigla('Sem Gols'),sigla('Sofre Primeiro')],
        ['%','%','%'],
        ['Marca o primeiro gol','Sem gols','Sofre o primeiro gol']
    ],[
        [sigla('Marca Ultimo'),sigla('Sem Gols'),sigla('Sofre Ultimo')],
        ['%','%','%'],
        ['Marca o último gol','Sem gols','Sofre o último gol']
    ]
]

export default function Tabela({grandeza,tabela}){
    const {
        campo,setCampo,toggle,setToggle,bandeira
      }=useContext(DarkContext)
    const [ordenada,setOrdenada]=useState(tabela)
    function ordenar(){
        if(campo==='time'){
            return setOrdenada(tabela)
        }
        const lista=[]
        let objeto
        let indice
        const used=[]
        for(let i=0;i<tabela.length;i++){
            objeto={}
            objeto[campo]=toggle?-Infinity:Infinity
            indice=null
            for(let k=0;k<tabela.length;k++){
                if(used.includes(k))continue
                if(toggle?tabela[k][campo]>objeto[campo]:tabela[k][campo]<objeto[campo]){
                    objeto={...tabela[k]}
                    indice=k
                }else if(tabela[k][campo]==objeto[campo]){
                    if(toggle?tabela[k][`c${parseInt(campo[1])+1}`]>objeto[`c${parseInt(campo[1])+1}`]:tabela[k][`c${parseInt(campo[1])+1}`]<objeto[`c${parseInt(campo[1])+1}`]){
                        objeto={...tabela[k]}
                        indice=k
                    }
                }
            }
            if(indice==null){
                for(let k=0;k<tabela.length;k++){
                    if(used.includes(k))continue
                    objeto={...tabela[k]}
                    indice=k
            }}
            lista.push(objeto)
            used.push(indice)
        }
        setOrdenada(lista)
    }
    useEffect(ordenar,[campo,toggle,tabela])
    const colors=['var(--vitoria)','var(--empate)','var(--derrota)']
    return(
        <Tudo>
            <Ord>
                {campos[grandeza-1][2].map((titulo,index)=>(
                    <footer>
                        {campo==`c${index+1}`?
                            <Seta specificLeft={false} specific={index==2&&grandeza!=1&&grandeza!=2}>
                                <h6>{!toggle?<ion-icon name="caret-down-outline"></ion-icon>:<ion-icon name="caret-up-outline"></ion-icon>}{titulo}</h6>
                            </Seta>:<></>}
                    </footer>
                ))}
            </Ord>
            <Quadro >
                <Linha color={'#afafaf'} first={true}>
                    <Campo time={true}></Campo>
                    {campos[grandeza-1][0].map((camp,index)=>(
                        <Campo  selected={campo==`c${index+1}`} onClick={()=>{if(campo!==`c${index+1}`){setCampo(`c${index+1}`)}else{setToggle(!toggle)}}}>
                        
                        <Bolinha color={colors[index]}>{camp}</Bolinha>
                    </Campo>
                    ))}
                </Linha>
                {ordenada.map((est,index)=>{
                    const {c1,c2,c3}=est
                    return(
                    <Linha color={index%2?'white':'#efefef'} >
                        <span><Escudo camp={bandeira} time={est.time}/></span>
                        <p>{c1=='-'?'-':`${c1.toFixed(grandeza==2?2:grandeza==6?1:0)}${campos[grandeza-1][1][0]}`}</p>
                        <p>{c2=='-'?'-':`${c2.toFixed(grandeza==2?2:grandeza==6?1:0)}${campos[grandeza-1][1][1]}`}</p>
                        <p>{c3=='-'?'-':`${c3.toFixed(grandeza==2?2:grandeza==6?1:0)}${campos[grandeza-1][1][2]}`}</p>
                    </Linha>
                )})}
            </Quadro>
        </Tudo>
    )
}
const Ord=styled.div`
position:relative;
height:22px;width:100%;
justify-content:space-around;
padding:0 15px 0 45px;box-sizing:border-box;
footer{
    width:5px;height:100%;background-color:;
overflow:visible;
}
`
const Seta=styled.div`
;font-size:22px;
top:2px;overflow:visible;
color:white;
justify-content:center;
h6{
    background-color:black;padding:3px;;border-radius:5px;
    font-size:12px;display:flex;white-space: nowrap;
}
${props=>props.specific?'position:absolute;right:1%;top:0;':''};
${props=>props.specificLeft?'position:absolute;left:1%;top:0;':''};
`
const Campo=styled.div`
width:${props=>props.time?'45px':'calc(33% - 7.3px)'};
background-color:#afafaf;
justify-content:center;

`
const Bolinha=styled.button`
max-width:60px;
width:100%;height:30px;border-radius:5px;
background-color:${props=>props.color};
justify-content:center;

`
const Tudo=styled.div`
img{height:80%;width:auto}
span{
    min-height:45px;min-width:45px;background-color:;
    display:flex;justify-content:center;align-items:center;
}
width:100%;
height:calc(100% - 75px);
flex-direction:column;
justify-content:space-between;

`
const Quadro=styled.div`border:2px solid #afafaf;
flex-direction:column;
height:97%;width:98%;
border-radius:10px;
overflow:hidden;overflow-y:scroll;
max-width:1000px;
::-webkit-scrollbar {
    width: 15px;
  }
  ::-webkit-scrollbar-track {
    border-radius:7.5px;
  }
  ::-webkit-scrollbar-thumb {
    background:#EFEFEF; 
    border-radius:7.5px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background:#cecece;
  }
   width:98%;p{font-size:15px;font-weight:300}
`
const Linha=styled.div`
img{cursor:pointer;height:40px;width:auto;}
p{
    width:33%;display:flex;justify-content:center;align-items:center;
};
min-height:45px;background-color:${props=>props.color};
width:100%;justify-content:space-between;
position:${props=>props.first?'sticky;top:0;':''};
`