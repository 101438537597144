import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import DarkContext from '../gerais/DarkContext'
import BuscarAnalise from './BuscarAnalise'
import Apostinha from './Apostinha'
import { _FAV, ligas } from "../ligas/contexts"

export default function Analise({jogo,noAlter}){
    
    const {setAnalise,
        analise,escoAnalise,setEscoAnalise,
        numeroEsc,setNumeroEsc,numeroGols,setNumeroGols

       }=useContext(DarkContext)
    const {camp,mandante,visitante}=jogo
    const umTime=mandante==visitante
    const paths=ligas.paths
    const context=ligas.contexts[paths.indexOf(camp)]
    const {listaNomes,listaTimes,path}=context

    const [apDisplay,setApDisplay]=useState(null)
    const [estrela,setEstrela]=useState(false)
    
    function determinarEstrela(){
        if(!apDisplay)return
        const listaEstrelas=JSON.parse(localStorage.getItem(_FAV))||[]
        if(listaEstrelas.includes(apDisplay.nome)){
            setEstrela(true)
        }else{setEstrela(false)}
    }
    function adicionarEstrela(){
        const listaEstrelas=JSON.parse(localStorage.getItem(_FAV))||[]
        if(estrela){
            const novaLista=[]
            for(let item of listaEstrelas){
                if(item!=apDisplay.nome){
                    novaLista.push(item)
                }
            }
            localStorage.setItem(_FAV, JSON.stringify(novaLista))
        }else{
            const novaLista=[...listaEstrelas,apDisplay.nome]
            localStorage.setItem(_FAV, JSON.stringify(novaLista))
        }
        determinarEstrela()
    }
    useEffect(determinarEstrela,[apDisplay])
    const {grandeza,metade,asc,c}=analise
    return (
                <Principal >
                    
                    {escoAnalise?<AbaEscolha >
                        <Listra>
                            <BtnEscolha texto={'Placar'} numero={1} estado={'grandeza'} />
                            <BtnEscolha texto={'Gols'} numero={2} estado={'grandeza'} />
                            <BtnEscolha texto={'Escanteios'} numero={6} estado={'grandeza'} />
                        </Listra>
                        <Linha cor={true}/>
                        {grandeza===null?
                        <header>
                            <Listra/>
                            <Listra/>
                            <Linha cor={false}/>
                        </header>
                        :<></>}
                        {grandeza==1||grandeza==2?
                            <Listra>
                            <BtnEscolha texto={'Partida'} numero={0} estado={'metade'} />
                            <BtnEscolha texto={'1º tempo'} numero={1} estado={'metade'} />
                            <BtnEscolha texto={'2º tempo'} numero={2} estado={'metade'} />
                        </Listra>
                        
                        :<></>}
                        {grandeza==1||grandeza==2?<Linha cor={true}/>:<></>}
                        {grandeza==1||grandeza==2||grandeza==6?<Listra>
                            <BtnEscolha texto={path?(umTime?'a favor':listaNomes[listaTimes.indexOf(mandante)]):''} numero={1} estado={'c'} />
                            <BtnEscolha texto={analise.grandeza==1?'Empate':'Ambos'} numero={2} estado={'c'} />
                            <BtnEscolha texto={path?(umTime?'contrários':listaNomes[listaTimes.indexOf(visitante)]):''} numero={3} estado={'c'} />
                        </Listra>:<></>}
                        {grandeza==6||grandeza==2?<Linha cor={true}/>:<Linha cor={false} />}
                        {grandeza==6||grandeza==2?
                            <Listra>
                                <BtnEscolha texto={'Mais que'} width={'calc(50% - 5px)'} numero={0} estado={'asc'} />
                                <BtnEscolha texto={'Menos que'} width={'calc(50% - 5px)'} numero={1} estado={'asc'} />
                            </Listra>
                        :<></>}
                        {grandeza==6?<Linha cor={false}/>:<></>}
                        {grandeza!=2?
                            <Listra>
                            </Listra>
                        :<></>}
                    </AbaEscolha>:
                    <BuscarAnalise setApDisplay={setApDisplay} confronto={jogo} query={{...analise,valor:grandeza==2?numeroGols:numeroEsc}}/>
                    }
                    <footer >
                    {grandeza==2||grandeza==6?
                            <Listra diferent={true}>
                                <BtnNumero handlePress={()=>{
                                    if(grandeza==2){
                                        if(numeroGols!=0)setNumeroGols(numeroGols-1)
                                    }else{
                                        if(numeroEsc!=1)setNumeroEsc(numeroEsc-1)
                                    }}} name={'chevron-back-outline'}/>
                                <h1 >{grandeza==2?numeroGols:numeroEsc}</h1>
                                <BtnNumero handlePress={()=>{
                                    if(grandeza==2){
                                        if(numeroGols!=6)setNumeroGols(numeroGols+1)
                                    }else{
                                        if(numeroEsc!=16)setNumeroEsc(numeroEsc+1)
                                    }}} name={'chevron-forward-outline'}/>
                            </Listra>
                        :<></>}
                    {
                        !noAlter?<BotaoAn margeado={grandeza!=2&grandeza!=6} azul={escoAnalise} onClick={()=>{
                        if(!escoAnalise&&(grandeza==7||grandeza==8||(grandeza==1&&asc==1))){
                            setAnalise({
                                grandeza:null,
                                metade:null,
                                c:null,
                                asc:null
                            })
                        }
                        setEscoAnalise(!escoAnalise)
                        
                        }} >
                        <p>{escoAnalise?'Analisar':'Alterar análise'}</p>
                    </BotaoAn>:<></>}
                    </footer>
                    {apDisplay&&!escoAnalise?<Apostinha reduzida={{comecou:apDisplay.comecou,tex:apDisplay.tex,ode:apDisplay.ode,nome:apDisplay.nome,noAlter}}/>:<></>}
                </Principal>
                
    )
}

const Aposta=styled.div`
cursor:pointer;
position:relative;
${props=>props.fixa?`position:absolute;bottom:8px;`:''};
box-sizing:border-box;
padding:0 10px 0 47px;
justify-content:space-between;
background-color:var(--${props=>props.backcolor});
height:40px;width:96%;
border-radius:20px;
color:${props=>props.color};
h5{font-size:13px;width:calc(100% - 48px);display:flex;justify-content:flex-start;}
h6{font-weight:700;font-size:20px}
`
const Estrela=styled.button`
color:${props=>props.ativo?'yellow':'#93bbf2'};
font-size:25px;
width:40px;height:40px;background-color:#6193dd;
border-radius:20px;
position:absolute;
top:0;
left:-0px;
justify-content:center;
`
const BtnEscolha=({width,estado,numero,texto})=>{
    const {
        analise,setAnalise,
       }=useContext(DarkContext)
    const novaAnalise={...analise}
    novaAnalise[estado]=numero
    if(estado=='grandeza'&&numero==1)novaAnalise.asc=0
    return(
        <BotaoEscolha shown={analise[estado]==numero} onClick={()=>{setAnalise(novaAnalise)}} width={width}>
            <p >{texto}</p>
        </BotaoEscolha>
    )
}
const BtnNumero=({name,handlePress})=>{
    return(
        <BotaoNumero  onClick={handlePress}>
            <ion-icon name={name}></ion-icon>
        </BotaoNumero>
    )
}
const BotaoNumero=styled.div`cursor:pointer;
font-size:28px;color:#545454;
justify-content:center;
height:40px;width:40px;
border-radius:5px
`
const Principal=styled.div`
width:95%;max-width:378px;
height:calc(100% - 0px);
flex-direction:column;
footer{
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    h1{margin:0;
        font-size:20px;
        color:#545454;height:40px;font-weight:600;
        display:flex;align-items:center;
    }
}
`
const AbaEscolha= styled.div`
width:100%;
height:210px;
flex-direction:column;
justify-content:flex-end;
`
const BotaoAn=styled.button`
background-color:${props=>props.azul?'#6193dd':'#E8E8E8'};
justify-content:center;
width:125px;
height:37px;
border-radius:5px;
margin-bottom:10px;
margin-top:${props=>props.margeado?15:0}px;
color:${props=>props.azul?'white':'black'};
`
const BotaoEscolha=styled.div`cursor:pointer;
justify-content:center;
height:40px;
border-radius:5px;
width:${props=>props.width||'calc(33.3% - 6.6px)'};
background-color:${props=>props.shown?'#7f7f7f':'transparent'};
p{
    font-size:12px;
    color:${props=>props.shown?'white':'#545454'}
}
`
const Listra=styled.div`
width:100%;
justify-content:${props=>props.diferent?'center':'space-between'};
align-items:${props=>props.diferent?'flex-start':'center'};
height:${props=>props.diferent?'45px':'55px'};
`
const Linha=styled.div`
height:2px;
width:100%;
background-color:${props=>props.cor?'#cccccc':'transparent'};
`