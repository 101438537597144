import { useContext, useEffect } from "react";
import styled from "styled-components";
import DarkContext from "../gerais/DarkContext";
import { useNavigate } from "react-router-dom";
export default function EscolherAposta({onMenu}){
    const {keys,setKeys,setBandeirando,setApostando,
        setPagina,setPrimeiraVez,
      }=useContext(DarkContext)

      const navigate=useNavigate()
      
    return(onMenu?
        <Tudo onMenu={onMenu}>
            <Btn onClick={()=>{navigate('/odds');setApostando(false);setPrimeiraVez(false)}}>
                    <ion-icon name="trending-up-outline"></ion-icon>
                    <p>Melhores apostas</p>
                </Btn>
                <Btn onClick={()=>{navigate('/favoritas');setApostando(false);setPrimeiraVez(false)}}>
                    <ion-icon name="star-outline"></ion-icon>
                    <p>Favoritas</p>
                </Btn>
                <Btn onClick={()=>{navigate('/multiplas');setApostando(false);setPrimeiraVez(false)}}>
                    <ion-icon name="layers-outline"></ion-icon>
                    <p>Treinar apostas fictícias offline</p>
                </Btn>
                <Btn onClick={()=>{navigate('/multiplas');setApostando(false);setPrimeiraVez(false)}}>
                    <ion-icon name="layers-outline"></ion-icon>
                    <p>Treinar apostas fictícias offline</p>
                </Btn>
        </Tudo>
        :
        <Layer >
            <Tudo>
            <Btn onClick={()=>{navigate('/odds');setApostando(false)}}>
                    <ion-icon name="trending-up-outline"></ion-icon>
                    <p>Melhores apostas</p>
                </Btn>
                <Btn onClick={()=>{navigate('/favoritas');setApostando(false)}}>
                    <ion-icon name="star-outline"></ion-icon>
                    <p>Favoritas</p>
                </Btn>
                <Btn onClick={()=>{navigate('/multiplas');setApostando(false);setPrimeiraVez(false)}}>
                    <ion-icon name="layers-outline"></ion-icon>
                    <p>Treinar apostas fictícias offline</p>
                </Btn>
                <Btn onClick={()=>{navigate('/torneios');setApostando(false);setPrimeiraVez(false)}}>
                    <ion-icon name="layers-outline"></ion-icon>
                    <p>Torneios de apostas fictícias</p>
                </Btn>
            </Tudo>
        </Layer>
    )
}
const Btn=styled.div`cursor:pointer;
border-radius:15px;
font-size:14px;
height:23%;
width:calc(100% - 16px);
background-color:#e8e8e8;
ion-icon{font-size:32px;margin-left:15px};
p{
width:calc(100% - 10px);
};
color:#545454;
`
const Layer=styled.div`
position:fixed;
;z-index:13;
width:100vw;
height:calc(100vh - 64px);
background-color:rgb(0,0,0,0.8);
max-width:400px;
top:64px;
flex-direction:column;
`
const Tudo=styled.div`
max-width:370px;
border-radius:20px;
h1{margin-top:50px;}
background-color:white;
box-sizing:border-box;
padding:5px 0 5px 0px;
height:${props=>props.onMenu?180:290}px;
width:${props=>props.onMenu?'50%':'94%'};
z-index:14;
justify-content:space-evenly;
flex-wrap:wrap;
position:absolute;${props=>props.onMenu?'top:10px;right:3%;':''};

`
