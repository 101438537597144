import { useContext, useEffect } from "react";
import styled from "styled-components";
import DarkContext from "../gerais/DarkContext";
import { useNavigate } from "react-router-dom";
import Escudo from "../pag_2/Escudo";
import { ligas } from "../ligas/contexts";
import { useState } from "react";
import { getTorneios, postCadastro, postLogin } from "../api";

export default function Cadastro(){
    const {user,setUser}=useContext(DarkContext)
    const navigate=useNavigate()
    const [usuario,setUsuario]=useState('')
    const [senha,setSenha]=useState('')
    function postar(){
        const promise=postCadastro({usuario,senha})
        promise.then(res=>{
            setUser(res.data)
            navigate(`/torneios`)
        })
    }
    useEffect(()=>{
        const input=document.getElementById(`searchField`)
        input.focus()             
    },[])
    return(
        <Tudo>
            <Titulo>
                Cadastro
            </Titulo>
             <input id="searchField"
              value={usuario} 
              onChange={e=>{setUsuario(e.target.value)}} 
            placeholder='usuário'></input>
            <input
              value={senha} 
              onChange={e=>{setSenha(e.target.value)}} 
            placeholder='senha'></input>
            <Postar onClick={postar}>
                Enviar
            </Postar>
            <Login onClick={()=>navigate(`/login`)}>
                Fazer login
            </Login>
        </Tudo>
    )
}
const Titulo=styled.div`
justify-content:center;
height:50px;width:100px;
border-radius:10px;
color:#008037;font-size:25px;
margin-top:20px;
`
const Postar=styled.div`
cursor:pointer;
justify-content:center;
background-color:#22b736;
height:50px;width:100px;
border-radius:10px;
color:white;
margin-top:20px;
`
const Login=styled.div`
cursor:pointer;
justify-content:center;
height:50px;width:100px;
border-radius:10px;
color:#008037;
margin-top:20px;
`
const Tudo=styled.div`
flex-direction:column;
background-color:white;
width:100%;
height:calc(100% - 0px);
max-width:370px;
box-sizing:border-box;
flex-wrap:wrap;
input{
    font-size:14px;padding-left:10px;box-sizing:border-box;
    height:40px;width:90%;max-width:400px;
    border-radius:10px;
    border:1px solid green;
    margin-top:20px;
}
`